<template>
<div style="width: 98%;">

<el-tabs  style="height: 100%;">
  <el-tab-pane label="壁纸分类"><wallcategory></wallcategory></el-tab-pane>
  <el-tab-pane label="头像分类"><profilecategory></profilecategory></el-tab-pane>
</el-tabs>
</div>

</template>
    
<script>
    import wallcategory from '../views/wallpaper/WallpaperCategory.vue';
    import profilecategory from '../views/profile/ProfileCategory.vue';
    export default {
        name: 'Category',
        components:{
          wallcategory,
          profilecategory
        },
        data(){
            return {
                tableData: [{
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
                }, {
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
                }, 
                {
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
                }]
            }
            
        }
    }
</script>
    
<style>

</style>