<template>
    <div>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>全局缓存设置</span>
                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
            </div>
            <div  class="text item">
                <el-button type="danger" @click="handleAllCache">清除全局缓存</el-button>
                <el-button type="primary" @click="handleClearWallpaperCache">清除全局壁纸缓存</el-button>
                <el-button type="warning" @click="handleClearProfileCache">清除全局头像缓存</el-button>
            </div>
        </el-card>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>分类缓存设置</span>
                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
            </div>
            <div  class="text item">
                <el-button type="primary" @click="handleUpdateWallpaper">更新壁纸分类</el-button>
                <el-button type="warning" @click="handleUpdateProfile">更新头像分类</el-button>
            </div>
        </el-card>
    </div>
</template>
<script>
import {clearWallpaperCache,clearProfileCache,updateWallCategoryCache,updateProfileCategoryCache,flushdb} from '../../api/setting'
export default {
    name: 'CacheSetting',
    data(){
        return{

        }
    },
    methods:{
        handleClearWallpaperCache(){
            clearWallpaperCache().then(res=>{
                if(res.data.status){
                    this.$message.success("操作成功")
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                this.$message.error("请求失败")
            })
        },
        handleClearProfileCache(){
            clearProfileCache().then(res=>{
                if(res.data.status){
                    this.$message.success("操作成功")
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                this.$message.error("请求失败")
            })
        },
        handleAllCache(){
            flushdb().then(res=>{
                if(res.data.status){
                    this.$message.success("操作成功")
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                this.$message.error("请求失败")
            })
        },
        handleUpdateWallpaper(){
            updateWallCategoryCache().then(res=>{
                if(res.data.status){
                    this.$message.success("更新成功")
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                this.$message.error("请求失败")
            })
        },
        //更新头像分类缓存
        handleUpdateProfile(){
            updateProfileCategoryCache().then(res=>{
                if(res.data.status){
                    this.$message.success("更新成功")
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                this.$message.error("请求失败")
            })
        }

    }
}
</script>
<style scoped>
    .box-card{
        margin-bottom: 18px;
    }
</style>

