import {logout} from '../api/user'
export default {
    install(Vue) {
      //全局函数：用户注销函数  
      Vue.prototype.logout = function() {
         //检查本地存储有无用户信息
         let user = JSON.parse(localStorage.getItem("user"));
         if(user != null && typeof(user) != "undefined" && user != ''){
           //发起用户退出请求
           logout(user.id).then(res=>{
             this.$message.info(res.data.msg);
           }).catch(error=>{
             this.$message.error("服务器请求失败")
           }).finally(()=>{
            //清除本地存储
            localStorage.clear()  
            //跳转到登录页
            this.$router.push("/login")
           })
         }else{
            //清除本地存储
            localStorage.clear()  
            //跳转到登录页
            this.$router.push("/login")
         }
        
         
      };
    }
  }