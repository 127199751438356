<template>
  <div class="wrapper">
    <div class="asideBox">
    </div>
    <div class="loginBox">
      <div style="margin: 20px 0; text-align: center; font-size: 24px">
        <b>用户登录</b>
      </div>
      <el-form :model="user" :rules="rules" ref="userForm">
        <el-form-item prop="username">
          <el-input
            size="medium"
            style="margin: 10px 0"
            maxlength="20"
            placeholder="账号"
            prefix-icon="el-icon-user"
            v-model="user.username"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            size="medium"
            style="margin: 10px 0"
            maxlength="20"
            placeholder="密码"
            prefix-icon="el-icon-lock"
            show-password
            v-model="user.password"
          ></el-input>
        </el-form-item>
        <el-form-item class="tool">
          <el-button
            type="primary"
            :disabled="user.username.trim() == '' || user.password.trim() == ''"
            style="width: 200px"
            size="medium"
            autocomplete="off"
            @click="handleLogin()"
            ><span style="margin-right: 5px"> 登</span><span>录</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
  
</template>

<script>
import { UserLogin } from "../api/user";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
export default {
  name: "Login",
  data() {
    return {
      bgPosition:1,
      user: { username: "", password: "" },
      nowTime: "",
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    //清除Token
    clearToken(){
        localStorage.clear()
    },
    //登录处理函数
    handleLogin() {
      this.clearToken()
      var that = this;
      //发起登录请求
      UserLogin(this.user).then((res) =>{
        if (res.data.status) {
          this.$message.success(res.data.msg)
          localStorage.setItem("token",res.data.data.token)
          localStorage.setItem("role",res.data.data.role)
          localStorage.setItem("user",JSON.stringify(res.data.data.user))
          that.$router.push("/home")
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(error=>{
          that.$message({
                  message: error,
                  type: 'error',
                  duration: 1300
          });
      })
    }
  },
  created() {
   },
};
</script>

<style>
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgb(241, 243, 244);
}

/* 左边 */
.wrapper .asideBox{
  display: inline-block;
  width: 300px;
  height: 420px;
  background: url('../assets/aside3.png');
  background-size: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1)
}

.wrapper .loginBox{
  display: inline-block;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);

  background-color: rgba(255, 255, 255);
  width: 440px;
  height: 380px;
  padding: 20px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.tool {
  margin: 30px 0;
  text-align: center;
  display: flex !important;
  justify-content: space-between !important;
}
.el-form-item--small .el-form-item__content,
.el-form-item--small .el-form-item__label {
  width: 80%;
  margin: 5px auto;
}
#div_nav {
  width: 100%;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.4);
}
#div_nav > #txt_date,
#div_nav > #txt_peel {
  padding-left: 10px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 30px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#txt_peel:hover {
  cursor: pointer;
  text-decoration: underline;
}

#div_nav > #div_peelPanel {
  width: 100%;
  padding: 20px;
  margin-bottom: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0 0 5px 5px;
  position: absolute;
  z-index: 100;
}
#div_peelPanel > li {
  display: inline-block;
  padding: 10px;
  list-style: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#div_peelPanel > li > img {
  width: 128px;
  height: 80px;
  cursor: pointer;
  border-radius: 0 0 5px 5px;
  transition: box-shadow ease-in-out 0.15s;
}
#div_peelPanel > li > img:hover {
  box-shadow: 0 0 8px rgba(102, 175, 255, 0.8);
}
</style>