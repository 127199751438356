//引入依赖包
import VueRouter from 'vue-router'
import Vue from 'vue'
import Login from "../views/Login"
import store from '@/utils/store'
import Layout from '../layout/Index'
import Home from '../components/Home'
import Category from '../components/Category'
import UploadWallpaper from '../components/wallpaper/Upload'
import WallpaperPhoto from '../components/wallpaper/WallpaperPhoto'
import WallpaperPhotoList from '../components/wallpaper/WallpaperPhotoList'
import ProfilePhoto from '../components/profile/ProfilePhoto'
import ProfilePhotoList from '../components/profile/ProfilePhotoList'
import UploadProfile from '../components/profile/Upload'
import Feedback from '../views/FeedBack'
import Help from '../views/Help'
import UserManagement from '../views/UserManagement'
import Setting from '../views/mysettings/Setting'
import UpdatePassword from '../views/UpdatePassword'
import WXUserManagement from '../views/WXUser'
import BaseManagement from '../components/BaseManagement'
import addBanner from '../views/banner/AddBanner'

Vue.use(VueRouter)
const routes =[
    {
        path: "/login",
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: "",
                redirect: "/login"
            },
            {
                path: "/home",
                name: '首页',
                component: Home
            },
            {
                path: "/baseManagement",
                name: '基础管理',
                component: BaseManagement
            },
            {
                path: "/category",
                name: '分类',
                component: Category
            },
            {
                path: "/wallpaper/WallpaperPhoto",
                name: '壁纸图片',
                component: WallpaperPhoto
            },
            {
                path: "/wallpaper/WallpaperPhotoList",
                name: '壁纸图片列表',
                component: WallpaperPhotoList
            },
            {
                path: "/wallpaper/upload",
                name: "上传壁纸",
                component: UploadWallpaper
            },
            {
                path: "/profile/ProfilePhoto",
                name: '头像图片',
                component: ProfilePhoto
            },
            {
                path: "/profile/ProfilePhotoList",
                name: '头像图片列表',
                component: ProfilePhotoList
            },
            {
                path: "/profile/upload",
                name: "上传头像",
                component: UploadProfile
            },
            {
                path: "/feedback",
                name: "用户反馈",
                component: Feedback
            },
            {
                path: "/help",
                name: "帮助手册",
                component: Help
            },
            {
                path: "/setting",
                name: "系统配置",
                component: Setting

            },
            {
                path: "/useradmin",
                name: "系统用户",
                component: UserManagement

            },
            {
                path: "/wxuser",
                name: "微信用户",
                component: WXUserManagement

            },
            {
                path: "/updatePassword",
                name: "修改密码",
                component: UpdatePassword

            },
            {
                path: "/addBanner",
                name: "添加轮播图",
                component: addBanner

            }

        ]
    }
]

//创建路由器对象
const router = new VueRouter({
    mode: 'history',
    routes: routes
})

router.beforeEach((to, from, next) => {
    localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称，为了在Header组件中去使用
    store.commit("setPath")  // 触发store的数据更新
    next()  // 放行路由
   
  })

//暴露路由器
export default router