import request from "../utils/request";

//获取公告内容
export function getNotice() {
    return request({
        url: '/notice/get',
        method: 'get'
    })
}

//保存公告
export function saveNotice(data) {
    return request({
        url: '/notice/save',
        method: 'post',
        data:{
            content:data
        }
    })
}


