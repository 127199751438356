import request from "../utils/request";


//分页获取轮播图
export function getListByPage(currentPage,pageSize) {
    return request({
        url: '/banner/list?page='+currentPage+'&limit='+pageSize,
        method: 'get'
    })
}

//新增轮播图
export function addBanner(data) {
    return request({
        url: '/banner/save',
        method: 'post',
        data:data
    })
}

//删除轮播图
export function deleteBanner(id) {
    return request({
        url: '/banner/delete?id='+id,
        method: 'delete'
    })
}

//查询单个轮播图
export function getOne(id) {
    return request({
        url: '/banner/getOne?id='+id,
        method: 'get'
    })
}

//修改轮播图
export function updateBanner(data) {
    return request({
        url: '/banner/update',
        method: 'put',
        data:data
    })
}