<template>
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="ID" width="140"> </el-table-column>
  
        <el-table-column prop="nickName" align="center" label="微信昵称">
        </el-table-column>
  
        <el-table-column prop="punkte" align="center" label="积分">
        </el-table-column>

        <el-table-column prop="avatarUrl" align="center" label="头像URL">
        </el-table-column>
  
        <el-table-column prop="createtime" align="center" label="创建时间">
        </el-table-column>

        <el-table-column prop="logintime" align="center" label="最后登录时间">
        </el-table-column>
  
        <el-table-column align="center" width="180" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
  
      <!-- 分页视图 -->
      <div class="block" style="text-align: center; margin-top: 15px">
        <el-pagination
          layout="prev, pager, next, jumper"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handlePageChange"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </template>
  <script>
import {getAll, deleteOne} from '../api/wxuser';

  export default {
    name: "WXUserManagement",
    data() {
      return {
        //控制修改对话框的显示与隐藏
        windowVisible: false,
        addWindowVisible: false,
        addOperate: true,
        //页大小
        pageSize: 5,
        //当前页
        currentPage: 1,
        //表格数据总和
        total: 0,
        //表格数据列表
        tableData: [],
      };
    },
    methods: {
      //当页数发生改变时，触发的函数
      handlePageChange(val) {
        var that = this;
        getAll(`${val}`, this.pageSize).then((res) => {
          that.total = res.data.total;
          that.tableData = res.data.data;
        });
      },
      //删除微信用户
      handleDelete(id){
        deleteOne(id).then(res=>{
            if(res.data.status){
              this.$message.success(res.data.msg);
            }else{
              this.$message.error("删除失败，请稍后重试");
            }
        }).catch(err=>{
          that.$message({
              message: "请求失败",
              type: "error",
              duration: 1500,
            });
        }).finally(()=>this.initTableData())
      },
      //初始化表格数据
      initTableData() {
        let that = this;
        getAll(this.currentPage, this.pageSize)
          .then((res) => {
            that.tableData = res.data.data;
            that.total = res.data.total;
          })
          .catch((error) => {
            that.$message({
              message: "请求失败",
              type: "error",
              duration: 1500,
            });
          });
      },
     
    },
    mounted() {
      this.initTableData();
    },
  };
  </script>
  
  <style>
  .around {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32%;
    height: 100px;
  }
  .white {
    color: white;
  }
  </style>