import request from "../utils/request";

//获取反馈列表
export function getList(currentPage,pageSize) {
    return request({
        url: '/feedback/getList?page='+currentPage+'&limit='+pageSize,
        method: 'get'
    })
}

//删除反馈
export function deleteFeedback(id) {
    return request({
        url: '/feedback/delete?id='+id,
        method: 'delete'
    })
}