<template>
  <div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        margin: 0px 0px 10px 5px;
      "
    >
      <el-page-header
        @back="goBack"
        title="返回上一级"
        style="
          color: rgb(64, 158, 255);
          display: inline !important;
          border: none;
        "
      ></el-page-header>
      <div style="float: right">
        <el-button
          type="danger"
          style="display: inline"
          :disabled="batchDeleteArr.length === 0"
          @click="batchDelete"
          >批量删除</el-button
        >
        <el-button
          type="danger"
          @click="deleteAll"
          :disabled="tableData.length === 0"
          >全部删除</el-button
        >
      </div>
    </div>
    <el-table
      ref="multipleTable"
      border
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column align="center" type="selection" width="55">
      </el-table-column>

      <el-table-column align="center" prop="id" label="ID" width="120">
      </el-table-column>

      <el-table-column
        align="center"
        prop="pic"
        label="图片预览"
        width="300"
        show-overflow-tooltip
      >
        <template slot-scope="scope"
          ><el-image
            :src="scope.row.pic"
            fit="cover"
            :preview-src-list="scope.row.pic"
            style="width: 100px; height: 100px"
          ></el-image
        ></template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="type"
        sortable
        label="类型"
        width="150"
      >
          <template slot-scope="scope">
              <el-tag
              :type="scope.row.type === 1 ? 'primary' : 'success'"
              disable-transitions>{{scope.row.type === 1 ? '本地上传':'网络图片'}}</el-tag>
          </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="time"
        sortable
        label="上传时间"
        width="250"
      >
      </el-table-column>

      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="handleCopyURL(scope.row)"
          >复制图片链接</el-button>
          <el-button
            type="danger"
            @click="deleteOne(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页视图 -->
    <div class="block" style="text-align: center; margin-top: 15px">
      <el-pagination
        layout="prev, pager, next,jumper"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="handlePageChange"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getProfilePhoto } from "../../api/profilePhoto";
import { deleteProfilePhoto } from "../../api/profilePhoto";
import { deleteAllProfilePhoto } from "../../api/profilePhoto";
export default {
  name: "ProfilePhotoList",
  data() {
    return {
      //当前的分类ID
      cid: 0,
      //表格数据列表
      tableData: [],
      //页大小
      pageSize: 5,
      //当前页
      currentPage: 1,
      //表格数据总和
      total: 0,
      batchDeleteArr: [],
    };
  },
  methods: {
    //获取壁纸列表
    initProfilePhotoData() {
      //获取路由参数
      this.cid = this.$route.query.cid;
      var that = this;
      getProfilePhoto(this.currentPage, this.pageSize, this.cid)
        .then((res) => {
          that.total = res.data.total;
          that.tableData = res.data.data;
        })
        .catch((error) => {
          that.$message({
            message: "请求数据失败",
            type: "error",
            duration: 1500,
          });
        });
    },

    //返回上一级
    goBack() {
      this.$router.push("/profile/ProfilePhoto");
    },

    //页数改变的处理函数
    handlePageChange(val) {
      var that = this;
      getProfilePhoto(`${val}`, this.pageSize, this.cid).then((res) => {
        that.total = res.data.total;
        that.tableData = res.data.data;
      });
    },
    //选择多行表格数据，并将这些数据保存到batchDeleteArr数组中
    handleSelectionChange(val) {
      this.batchDeleteArr = val;
    },

    //删除表格全部数据
    deleteAll() {
      this.$confirm("确认删除该分类下的所有图片?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //显示加载动画
          const loading = this.$loading({
            lock: true,
            text: "正在删除...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          deleteAllProfilePhoto(this.cid)
            .then((res) => {
              this.$message({
                type: "success",
                message: res.data.msg,
              });

              //刷新表格数据
              this.initProfilePhotoData();
            })
            .catch((error) => {
              that.$message({
                message: "请求失败",
                type: "error",
                duration: 1500,
              });
            })
            .finally(() => {
              //关闭加载动画
              loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //删除单个表格数据
    deleteOne(row) {
      let deleteData = [{ id: row.id, cid: row.cid, filekey: row.filekey }];
      //显示加载动画
      const loading = this.$loading({
        lock: true,
        text: "正在删除...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      deleteProfilePhoto(deleteData)
        .then((res) => {
          this.$message({
            message: res.data.msg,
            type: "success",
            duration: 1000,
          });
          //刷新表格数据
          this.initProfilePhotoData();
        })
        .catch((error) => {
          that.$message({
            message: "请求失败！",
            type: "error",
            duration: 1500,
          });
        })
        .finally(() => {
          //关闭加载动画
          loading.close();
        });
    },
    //批量删除按钮触发事件
    batchDelete() {
      var that = this;
      //遍历选中的行，将行的部分信息存储起来赋值给deleteData
      let deleteData = this.batchDeleteArr.map((item) => {
        const id = item.id;
        const filekey = item.filekey;
        const cid = item.cid;
        return { id, filekey, cid };
      });
      
      //显示加载动画
      const loading = this.$loading({
        lock: true,
        text: "正在删除...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //发送请求将数据删除
      deleteProfilePhoto(deleteData)
        .then((res) => {
          //提示弹窗
          this.$message({
            message: res.data.msg,
            type: "success",
            duration: 1000,
          });
          //刷新表格数据
          this.initProfilePhotoData();
        })
        .catch((error) => {
          that.$message({
            message: "请求失败！",
            type: "error",
            duration: 1500,
          });
        }).finally(()=>{
          loading.close()
        })
    },
     //复制图片链接
     handleCopyURL(row){
      var that = this;
      console.log(row)
      this.$copyText(row.pic).then(
	      e=>{
	        that.$notify({
            title: '操作提示',
            message: '复制成功',
            duration: 2000
          });
	      },
	      e=>{
	        console.log('复制失败：', e);
	      }
      )
    }
  },
  mounted() {
    //初始化表格数据
    this.initProfilePhotoData();
  },
};
</script>

<style>
</style>