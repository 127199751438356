<template>
       <div>
        <div style="float:right">
            <el-button type="text" round @click="showAddDialog" style="font-weight:bold">添加用户</el-button>
        </div>
            <el-table
            :data="tableData"
            style="width: 100%">
                <el-table-column
                prop="id"
                label="用户ID"
                width="190">
                </el-table-column>

                <el-table-column
                prop="username"
                align="center"
                label="用户名">
                </el-table-column>


                <el-table-column
                prop="name"
                align="center"
                label="称呼">
                </el-table-column>



                <el-table-column
                prop="rolelist"
                align="center"
                
                label="用户角色">
                    <template slot-scope="scope">
                        <span v-show="scope.row.roleList.length == 0" style="color:#909399">无角色</span>
                        <el-tag v-for="(item,index) in scope.row.roleList" style="margin-right: 10px;">{{ item.describe }}</el-tag>
                    </template>
                </el-table-column>
                

                <el-table-column align="center" width="290" label="操作">
                    <template slot-scope="scope">
                        <el-dropdown style="margin-right: 10px;" size="small">
                            <el-button type="primary" >
                                修改<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="showEditDialog(scope.row.id)">基本信息</el-dropdown-item>
                                <el-dropdown-item @click.native="showAlterDialog(scope.row.id)">修改密码</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button
                        size="small"
                        type="danger"
                        @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 编辑视图 -->
            <el-dialog  title="修改基本信息" :visible.sync="editwindowVisible" append-to-body @close="editBaseDialogClosed">
                <el-form label-width="80px"  :model="editForm" ref="editFormRef" >
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="editForm.username"></el-input>
                    </el-form-item>
                    <el-form-item label="称呼" prop="name">
                        <el-input v-model="editForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="角色" prop="roleList">
                        <el-checkbox-group v-model="editForm.roleList">
                            <el-checkbox label=1>管理员</el-checkbox>
                            <el-checkbox label=2>开发人员</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitEditForm" :disabled="editForm.username.trim() == '' || editForm.name.trim() == ''">提交</el-button>
                    <el-button @click="editwindowVisible = false">取消</el-button>
                </span>
            </el-dialog>

            <!-- 添加视图 -->
            <el-dialog title="添加分类" :visible.sync="addWindowVisible" append-to-body @close="addDialogClosed">
                <el-form label-width="80px" :rules="addFormRules" :model="addForm" ref="addFormRef" >
                    <el-form-item label="登录名" prop="username">
                        <el-input v-model="addForm.username" @blur="addForm.title=$event.target.value.trim()"></el-input>
                    </el-form-item>
                    <el-form-item label="昵称" prop="name">
                        <el-input v-model="addForm.name" @blur="addForm.title=$event.target.value.trim()"></el-input>
                    </el-form-item>
                    <el-form-item label="角色" prop="roleList">
                        <el-checkbox-group v-model="addForm.roleList">
                            <el-checkbox label=1>管理员</el-checkbox>
                            <el-checkbox label=2>开发人员</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="用户密码" prop="password">
                        <el-input type="password" v-model="addForm.password"></el-input>
                    </el-form-item> 
                    <el-form-item label="确认密码" prop="repassword">
                        <el-input type="password" v-model="addForm.repassword" ></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="resetForm('addFormRef')" type="info">重置</el-button>
                    <el-button type="primary" @click="submitAddForm" >提交</el-button>
                    <el-button @click="addWindowVisible = false">取消</el-button>
                </span>
            </el-dialog>

             <!-- 修改密码视图 -->
             <el-dialog title="修改密码" :visible.sync="passwordwindowVisible" append-to-body @close="passwordDialogClosed">
                <el-form label-width="80px" :model="alterPasswordForm" ref="alterPasswordFormRef" >
                    <el-form-item label="新密码" prop="password">
                        <el-input type="password" v-model="alterPasswordForm.newpassword"></el-input>
                    </el-form-item> 
                </el-form>
                <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitAlterForm" >提交</el-button>
                <el-button @click="passwordwindowVisible = false">取消</el-button>
                </span>
            </el-dialog>

            <!-- 分页视图 -->
            <div class="block" style="text-align: center;margin-top: 15px;">
                <el-pagination
                    layout="prev, pager, next"
                    :page-size="pageSize"
                    :current-page="currentPage"
                    @current-change="handlePageChange"
                    :total="total">
                </el-pagination>
            </div>
    </div>
</template>
<script>
import {getAllUser} from '../api/user'
import {getOneUser} from '../api/user'
import {updateUser} from '../api/user'
import {addUser} from '../api/user'
import {deleteUser} from '../api/user'
import {updatePassword} from '../api/user'
export default {
    name: 'UserManagement',
    data(){
        var notNull = (rule, value, callback) => {
            if( value === null || value.trim() === ''){
                callback(new Error('请输入内容'));
            }else{
                callback()
            }
        };
        var checkSame = (rule, value, callback) => {
            if( value === null || value.trim() === ''){
                callback(new Error('请输入内容'));
            }else if(value != this.addForm.password){
                callback(new Error('密码不一致'));
            }else{
                callback()
            }
        };
        
        return {
            editForm:{
                id:0,
                username:'',
                name:'',
                roleList:[]
            },
            alterPasswordForm:{
                id:'',
                newpassword:''
            },
            addForm:{
                id:0,
                username:'',
                name:'',
                roleList:[],
                password:'',
                repassword:''
            },
            addFormRules:{
                name: [
                    { required: true, message: "请输入登录名", trigger: "blur" },
                    { validator: notNull, trigger: 'blur' }
                ],
                username: [
                    { required: true, message: "请输入用户名", trigger: "blur" },
                    { validator: notNull, trigger: 'blur' }
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' },
                    { validator: notNull, trigger: 'blur' }
                ],
                repassword: [
                    {required: true,message: '请输入确认密码', trigg: 'blur'},
                    { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' },
                    { validator: checkSame, trigger: 'blur' }
                ],
            },
            //控制对话框的显示与隐藏
            editwindowVisible: false,
            passwordwindowVisible:false,
            addWindowVisible:false,
            addOperate: true,
            //页大小
            pageSize: 5,
            //当前页
            currentPage: 1,
            //表格数据总和
            total: 0,
            //表格数据列表
            tableData: []
        }
    },
    methods:{
        //初始化表格数据
        initTableData(){
            getAllUser().then(res=>{
                if(res.data.status){
                    this.tableData = res.data.data
                    this.total = res.data.total
                }else{
                    this.$message.error("请求发生异常")
                }
            }).catch(error=>{
                this.$message.error("请求失败，请联系管理员")
            })
        },
        //当页数发生改变时，触发的函数
        handlePageChange(val){
            var that = this;
            getWallpaperCategory(`${val}`,this.pageSize).then(res=>{
                that.total = res.data.total
                that.tableData = res.data.data;
            })
            
        },
        //提交修改表单触发事件
        submitEditForm(){
            var that = this;
            updateUser(this.editForm).then(res=>{
                if(res.data.status){
                    this.$message({
                        message: res.data.msg,
                        type: 'success',
                        duration: 1000
                    });
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error',
                        duration: 1000
                    });
                }
                //关闭弹窗
                this.editwindowVisible = false;
                //刷新表格数据
                that.initTableData();
            }).catch(error=>{
                that.$message({
                        message: "请求失败",
                        type: 'error',
                        duration: 1200
                    });
            })
            
        },
        handleDelete(id){
            let that = this;
            this.$confirm('确定要删除吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteUser(id).then(res=>{
                    if(res.data.status){
                        this.$message.success(res.data.msg)
                    }else{
                        this.$message.warning(res.data.msg)
                    }
                }).catch(err=>{
                    this.$message.error('请求失败')
                }).finally(f=>{
                    that.initTableData()
                })
            }).catch(() => {this.$message.info('取消成功')})
            
        },
        //重置添加表单
        resetForm(addFormRef) {
            this.$refs[addFormRef].resetFields();
        },

        

        //点击编辑事件函数
        showEditDialog(id){
            var that = this;
            getOneUser(id).then(res=>{
                that.editForm = res.data.data
                that.editForm.roleList = that.editForm.roleList.map(item=>{ return item.id+""})
            })
            this.editwindowVisible = true;
        },

        //点击修改密码，弹出修改密码框
        showAlterDialog(id){
            this.alterPasswordForm.id = id;
            this.passwordwindowVisible = true;
        },

        //显示添加窗口
        showAddDialog(){
            this.addWindowVisible = true;
        },
        //监听修改基本信息表单的关闭事件
        editBaseDialogClosed(){
            this.$refs.editFormRef.resetFields();
        },
        //监听添加用户表单的关闭事件
        addDialogClosed(){
            this.$refs.addFormRef.resetFields();
        },
        //监听修改密码表单的关闭事件
        passwordDialogClosed(){
            this.$refs.alterPasswordFormRef.resetFields();
        },
        //提交修改密码表单
        submitAlterForm(){
            let that = this;
            let newpassword = this.alterPasswordForm.newpassword;
            //检查密码是否为空
            if(newpassword == null || newpassword.trim() == ''){
                this.$message.warning("请输入密码")
                return;
            }
            //发起请求，修改密码
            updatePassword(this.alterPasswordForm.id,newpassword).then(res=>{
                if(res.data.status){
                    this.$message.success("修改成功")
                    that.initTableData()
                    that.passwordwindowVisible  = false
                }else{
                    this.$message.warning("修改失败，请稍后重试")
                    return false;
                }
            }).catch(err=>{
                this.$message.error("请求失败，"+err)
            })

            
        },
        //提交添加用户表单
        submitAddForm(){
            let that = this;
            this.$refs["addFormRef"].validate(valid =>{
                if(valid) {
                    addUser(this.addForm).then(res=>{
                        if(res.data.status){
                            this.$message.success(res.data.msg)
                            that.initTableData()
                            that.addWindowVisible = false
                        }else{
                            this.$message.warning(res.data.msg)
                            return false;
                        }
                    }).catch(err=>{
                        this.$message.error("请求失败，"+err)
                    })
                } else {
                    this.$message.warning('请正确填写表单')
                    return false;
                }
            })
            
        }
    },
    created(){
        this.initTableData()
    }

}
</script>
<style scoped>

</style>
