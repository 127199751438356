<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="text-align: center">
        <span>使用说明</span>
        <!-- <el-button style="float: right; padding: 3px 0" type="text"
          >操作按钮</el-button
        > -->
      </div>
      <div><span>使用建议</span><i class="el-icon-s-help"></i></div>
      <br />
      <p>1、对图片进行完删除、修改后,建议更新缓存,保证缓存数据的一致性</p>
      <br />
      <p>2、上传图片后,如果切换分类再上传,需刷新页面</p>
      <br />

      <div><span>常见问题</span><i class="el-icon-s-help"></i></div>
      <br />
      <p style="font-weight: bold">常见问题<label class="layui-icon layui-icon-help" style="padding: 0px 3px"></label></p></br>
        <p>Q1:忘记密码?</p>
        <p>W1:系统有默认的账号密码，忘记请联系开发人员修改密码</p>
        <hr>
        <p>Q2:删除分类需要什么条件?</p>
        <p>W2:清空该分类下的所有图片</p>
        <hr>
        <p>Q3:网络图片和本地图片有什么区别?</p>
        <p>W3:网络图片是站外图片 (需要在开发设置里设置download合法域名)本地图片是使用七牛云存储的图片</p>
        <hr>
        <p>Q4:上传本地图片失败，有哪些原因？</p>
        <p>W4:1、图片重复上传,导致入库失败</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、图片大于10MB,导致上传失败</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3、图片上传数量不超过30张 </p>、
    </el-card>
  </div>
</template>
<script>
export default {
  name: "Help",
};
</script>
<style scoped>
</style>
