<template>
  <div>
    <div style="text-align: center">
      <el-card class="box-card mycard">
        <el-select
          v-model="value"
          filterable
          placeholder="请选择分类"
          style="position: absolute; left: 10px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-button
          size="small"
          type="success"
          @click="submitUpload"
          :disabled="fileList.length === 0"
          >开始上传</el-button
        >
        <el-button
          style="margin-left: 20px;"
          size="small"
          type="danger"
          @click="handleClear"
          :disabled="fileList.length === 0"
          >清空文件列表</el-button
        >

        <el-dropdown style="margin-left: 20px;">
          <el-button type="primary">
            导入功能<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="download()">下载导入模板Excel</el-dropdown-item>
            <el-dropdown-item  @click.native="showDialog()">开始批量导入</el-dropdown-item>
          </el-dropdown-menu>
         
        </el-dropdown>
      </el-card>
      <el-upload
        class="upload-demo"
        v-loading.fullscreen.lock="fullscreenLoading"
        ref="upload"
        :on-change="handleChange"
        :on-remove="handleRemove"
        drag
        action=""
        :limit="max"
        accept=".jpg , .png"
        :http-request="handleHttpRequest"
        :file-list="fileList"
        :auto-upload="false"
        :before-upload="beforeAvatarUpload"
        list-type="picture"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将图片拖到此处，或<em>点击选择图片</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          只能上传jpg/png文件,单个图片不超过10MB，一次性最多上传20张
        </div>
      </el-upload>
    </div>

      <!-- 导入功能视图 -->
      <el-dialog title="导入Excel文件" :visible.sync="importWindowVisible" append-to-body @close="importDialogClosed">
        <el-upload
            class="upload-demo"
            action=""
            :on-preview="handlePreview"
            :before-remove="beforeRemove"
            :auto-upload="false"
            ref="importRef"
            accept=".xlsx"
            :http-request="handleImportRequest"
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="importList">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
                
          <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="submitImportFile">导入</el-button>
              <el-button @click="importWindowVisible = false">取消</el-button>
          </span>
      </el-dialog>
  </div>
</template>
<script>
import { getTitle,batchNetworkPhoto,uploadProfilePhoto } from "../../api/profilePhoto";
export default {
  name: "UploadProfile",
  data() {
    return {
      value:'',
      fileList: [],
      importList:[],
      max:20,
      success: 0,
      fail: 0,
      total: 0,
      fullscreenLoading: false,
      importWindowVisible:false,
      options: [],
    };
  },
  methods: {
     //点击下载
     download() {  
      var a = document.createElement('a') // 创建一个<a></a>标签
      a.href = '../template.xlsx'         //重点（如测试发现下载文件不存在/找不到，检查路径）
      a.download = '导入模板.xlsx'         // 设置下载文件文件名
      a.style.display = 'none'           // 隐藏a标签
      document.body.appendChild(a)       // 将a标签追加到文档对象中
      a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove()                        // 一次性的，用完就删除a标签              
    },
    //清空文件列表
    handleClear() {
      this.$refs.upload.clearFiles();
      this.fileList = this.$refs.upload.fileList;
      this.success = 0;
      this.fail = 0;
    },
    //文件列表发生变化触发事件
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    //手动移除文件列表（点击x的时候触发）
    handleRemove(file, fileList){
      this.fileList = fileList;
    },

    //上传之前的回调函数
    beforeAvatarUpload(file) {
      var that = this;
      
    },

    //发起上传壁纸请求
    handleHttpRequest(params){
      const formData = new FormData();
      const that = this;
      formData.append("file", params.file); 
      formData.append("cid",that.value)        
      uploadProfilePhoto(formData)
        .then(() =>{
          that.success = that.success + 1
        } )
        .catch(_ =>{
          that.fail = that.fail + 1
        } )
    },

    //开始上传事件
     submitUpload() {
      var that = this;
      if(this.value == '' || this.value == null){
          this.$message.warning("请选择壁纸分类！");
          return;
      }
      let isLt10M = false;
      this.fileList.map(item=>{
        if(item.size / 1024 / 1024 > 10){
          isLt10M = true;
          return;
        }
      })
      if (isLt10M) {
        this.$message.error("文件列表含超过10MB的图片,上传取消");
        return;
      }
      this.size = this.fileList.length
      //显示加载动画
      var loading = that.$loading({
            lock: true,
            text: '文件上传中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })

     this.$refs.upload.submit()
      var s = setInterval(()=>{
        var flag = that.success + that.fail == that.size;
         if(flag){
          loading.close()
          that.$message.info("执行完毕。共："+that.success+"成功，"+that.fail+"个失败");
          clearInterval(s)
         }       
      },1000)
      
    },

    //获取壁纸所有分类
    getProCategory() {
      let that = this;
      getTitle()
        .then((res) => {
          let categoryArr = res.data.data;
          that.options = categoryArr.map((item) => {
            const title = item.title;
            const cid = item.cid;
            return { value: cid, label: title };
          });
        })
        .catch((error) => {
          that.$message({
            message: "请求数据失败",
            type: "error",
            duration: 1500,
          });
        });
    },
     //导入excel请求
     handleImportRequest(params){
      const formData = new FormData();
      var that = this;
      formData.append("file", params.file);     
      batchNetworkPhoto(formData).then((res) =>{
        console.log(res.data.status)

          if(res.data.status === true){
            that.$message({
                message: "导入成功",
                type: 'success',
                duration: 1500
            });
            that.importWindowVisible = false;
          }else{
            that.$message({
                message: "导入失败，请联系管理员",
                type: 'error',
                duration: 1500
            });
          }
        } )
        .catch(_ =>{
          that.$message({
                message: "请求失败",
                type: 'error',
                duration: 1500
            });
        } )

    },
    //提交导入的文件
    submitImportFile(){
      if(this.importList.length == 0){
        this.$message({
            message: "请选择excel文件",
            type: 'warning',
            duration: 1500
        });
        return;
      }
      this.$refs.importRef.submit()
    },
     //显示弹窗
     showDialog(){
      this.importWindowVisible = true
    },
    handlePreview(file){

    },
    beforeRemove(file, fileList){

    },
    handleExceed(files, fileList){

    },
    importDialogClosed(){
      this.importWindowVisible = false
    }
  },
  
  //钩子函数
  mounted() {
    this.getProCategory();
  },
};
</script>

<style>
.mycard {
  height: 80px;
  margin-bottom: 30px;
  display: flex;
  position: relative;
  justify-content: center;
}
.around {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 32%;
  height: 100px;
}
.white {
  color: white;
}
</style>