import request from "../utils/request";

//新增友情小程序
export function addFriendship(data) {
    return request({
        url: '/friendship/save',
        method: 'post',
        data:data
    })
}

//获取全部的友情小程序
export function getAll() {
    return request({
        url: '/friendship/getAll',
        method: 'get'
    })
}

//删除友情小程序
export function deleteFriendship(id) {
    return request({
        url: '/friendship/delete?id='+id,
        method: 'delete'
    })
}

//查询单个友情小程序
export function getOne(id) {
    return request({
        url: '/friendship/getOne?id='+id,
        method: 'get'
    })
}

//修改友情小程序
export function updateFriendship(data) {
    return request({
        url: '/friendship/update',
        method: 'put',
        data:data
    })
}