import request from "../utils/request";

//获取壁纸数量
export function wallpaperAmount() {
    return request({
        url: '/wallpaper/getTotal',
        method: 'get'
    })
}

//获取头像数量
export function profileAmount() {
    return request({
        url: '/profile/getTotal',
        method: 'get'
    })
}

//获取壁纸比例
export function wallpaperRatio() {
    return request({
        url: '/wallpaper/getRatio',
        method: 'get'
    })
}

//获取头像比例
export function profileRatio() {
    return request({
        url: '/profile/getRatio',
        method: 'get'
    })
}