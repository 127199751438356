<template>
  <el-container style="min-height: 100vh">
    <Aside/>
    <el-container direction="vertical">
        <Header/>
        <el-main>
          <Main/>
        </el-main>
       
        <!-- <Footer/> -->
    </el-container>
  </el-container>
</template>

<script>
import Aside from "@/layout/Aside";
import Header from "@/layout/Header";
import Main from "@/layout/Main";
import Footer from "@/layout/Footer";

export default {
  components: {Aside, Header, Main, Footer},
  data(){
    return {
      collapseBtnClass: 'el-icon-s-fold'
    }
  },
  mounted() {
  }

}
</script>

<style scoped>

</style>
