<template>
  <div>
    <el-card class="box-card" style="width: 99%;margin: 0 auto;">
      <el-descriptions title="基础信息">
        <el-descriptions-item label="应用名称">青枫壁纸后台</el-descriptions-item>
        <el-descriptions-item label="登录用户">{{ user.username }}</el-descriptions-item>
        <el-descriptions-item label="用户昵称">{{ user.name }}</el-descriptions-item>
        <el-descriptions-item label="系统版本">
          <el-tag size="small">1.4.0</el-tag>
        </el-descriptions-item>
    </el-descriptions>
    </el-card>
    <!-- 统计面板 -->
     <el-row type="flex" class="row-bg" justify="space-around" style="margin-top: 20px;">
      <el-card class="box-card around" >
          <p class="center">壁纸数量：{{wallpaperAmount }}张</p>
          <div class="square"></div>
          <div class="square2"></div>
      </el-card>
      <el-card class="box-card around" >
          <p class="center">头像数量：{{ profileAmount }} 张</p>
          <div class="square"></div>
          <div class="square2"></div>

      </el-card>
      <el-card class="box-card around">
          <p class="center">总数量：{{wallpaperAmount+profileAmount}}张</p>
          <div class="square"></div>
          <div class="square2"></div>
      </el-card>
    </el-row>

    <el-row type="flex" class="row-bg" justify="space-around">
      <el-card class="box-card ch" id="wallpaperAnalysis"></el-card>
      <el-card class="box-card ch" id="profileAnalysis"></el-card>
    </el-row>
    
  </div>


</template>
  
  <script>
  import * as echarts from 'echarts';
  import {wallpaperAmount} from '../api/statistic'
  import {profileAmount} from '../api/statistic'
  import {wallpaperRatio} from '../api/statistic'
  import {profileRatio} from '../api/statistic'
  
  export default {
    name: 'Home',
    data(){
      return {
        user:{},
        wallpaperAmount:0,
        profileAmount:0,
        wallpaperRatio:[],
        profileRatio:[]
    };
    },
    methods:{
      //初始化图标
      initEcharts() {
       
      

      },
      //获取壁纸数量
      initWallpaperTotal(){
          var that = this;
          wallpaperAmount().then(res=>{
            console.log(res.data.data);
            that.wallpaperAmount = res.data.data;
          }).catch(error=>{
              that.$message({
                      message: "请求失败",
                      type: 'error',
                      duration: 1200
              });
          })
      },
      //获取头像数量
      initProfileTotal(){
          var that = this;
          profileAmount().then(res=>{
              that.profileAmount = res.data.data;
          }).catch(error=>{
              that.$message({
                      message: "请求失败",
                      type: 'error',
                      duration: 1600
                  });
          })
      },
      //获取壁纸数据比例
      initWallRatio(){
          var that = this;
          wallpaperRatio().then(res=>{
            that.wallpaperRatio = res.data.data;
            var wallpaperChart = echarts.init(document.getElementById('wallpaperAnalysis'));
            var wallpaperOption;
            wallpaperOption = {
                title: {
                    text: '壁纸分类占比',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        name: '壁纸分类占比',
                        type: 'pie',
                        radius: '50%',
                        data: that.wallpaperRatio,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            wallpaperChart.setOption(wallpaperOption);
            window.addEventListener("resize", () => {wallpaperChart.resize(); });
          })
          
      },

      //获取头像数据比例
      initProfileRatio(){
          var that = this;
          profileRatio().then(res=>{
            that.profileRatio = res.data.data;
            var profileChart = echarts.init(document.getElementById('profileAnalysis'));
          var profileOption;
          profileOption = {
              title: {
                  text: '头像分类占比',
                  left: 'center'
              },
              tooltip: {
                  trigger: 'item'
              },
              legend: {
                  orient: 'vertical',
                  left: 'left'
              },
              series: [
                  {
                      name: '头像分类占比',
                      type: 'pie',
                      radius: '50%',
                      data: that.profileRatio,
                      emphasis: {
                          itemStyle: {
                              shadowBlur: 10,
                              shadowOffsetX: 0,
                              shadowColor: 'rgba(0, 0, 0, 0.5)'
                          }
                      }
                  }
              ]
          };
          profileChart.setOption(profileOption);
          // 随着屏幕大小调节图表
          window.addEventListener("resize", () => {profileChart.resize(); });
          })
         
      },
       
    },
    mounted(){
      this.initWallpaperTotal()
      this.initWallRatio()
      this.initProfileTotal()
      this.initProfileRatio()
    },
    created(){
      let user = JSON.parse(localStorage.getItem("user"));
      this.user = user;
    }
  }
  </script>
  
  <style>
    .around{
      box-shadow: none !important;
      border: none;
      display: flex; 
      align-items: center;
      justify-content: center;
      width: 32%;
      height: 100px;
      color: #333333;
      background: rgba(193, 224, 254, 0.5);
      position: relative;
      z-index: 100;
    }

    .around .square{
      position: absolute;
      display: inline-block;
      width: 60px;
      height: 60px;
      bottom: 20px;
      z-index: 0;
      right: 0px;
      background: rgb(255, 255, 255,0.75);
      transform:translate(12%, 4%)  rotate(30deg);;
    }

    .around .square2{
      position: absolute;
      display: inline-block;
      width: 60px;
      height: 60px;
      bottom: 20px;
      z-index: 0;
      right: 0px;
      background: rgb(255, 255, 255,0.75);
      transform:translate(12%, 4%)  rotate(60deg);;
    }

  
    .ch{
      margin-top: 30px;
      width: 45%;
      height: 350px;
      padding: 20px;
    }
  </style>