<template>
  <div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="ID" width="330"> </el-table-column>

      <el-table-column prop="nickname" align="center" label="微信名称">
      </el-table-column>

      <el-table-column prop="content" align="center" label="内容" width="230">
      </el-table-column>

      <el-table-column prop="time" align="center" label="反馈时间">
      </el-table-column>

      <el-table-column prop="contact" align="center" label="联系方式">
      </el-table-column>

      <el-table-column align="center" width="180" label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页视图 -->
    <div class="block" style="text-align: center; margin-top: 15px">
      <el-pagination
        layout="prev, pager, next, jumper"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="handlePageChange"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getList } from "../api/feedback"

import {deleteFeedback} from "../api/feedback"

export default {
  name: "Feedback",
  data() {
    return {
      //添加表单的数据项
      addForm: {
        title: "",
      },
      //修改表单的数据项
      editForm: {
        title: "",
        is_show: "",
        cid: 0,
      },
      //修改表单的验证规则对象
      editFormRules: {
        title: [
          //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
          {
            required: true,
            message: "请输入分类名称",
            trigger: "blur",
          },
        ],
        status: [
          //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
          {
            required: true,
            message: "请选择状态",
            trigger: "blur",
          },
        ],
      },
      //控制修改对话框的显示与隐藏
      windowVisible: false,
      addWindowVisible: false,
      addOperate: true,
      //页大小
      pageSize: 5,
      //当前页
      currentPage: 1,
      //表格数据总和
      total: 0,
      //表格数据列表
      tableData: [],
    };
  },
  methods: {
    //当页数发生改变时，触发的函数
    handlePageChange(val) {
      var that = this;
      getList(`${val}`, this.pageSize).then((res) => {
        that.total = res.data.total;
        that.tableData = res.data.data;
      });
    },
    //根据ID删除反馈
    handleDelete(id) {
      var that = this;
      deleteFeedback(id).then((res) => {
        if (res.data.status) {
          this.$message({
            message: res.data.msg,
            type: "success",
            duration: 1000,
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 1000,
          });
        }
        //刷新表格数据
        that.initTableData();
      });
    },
    //初始化表格数据
    initTableData() {
      let that = this;
      getList(this.currentPage, this.pageSize)
        .then((res) => {
          that.tableData = res.data.data;
          that.total = res.data.total;
        })
        .catch((error) => {
          that.$message({
            message: "请求失败",
            type: "error",
            duration: 1500,
          });
        });
    },
    //提交添加表单
    submitAddForm() {
      var that = this;
      addWallpaperCategory(this.addForm)
        .then((res) => {
          if (res.data.status) {
            this.$message({
              message: res.data.msg,
              type: "success",
              duration: 1000,
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 1000,
            });
          }
          //关闭弹窗
          this.addWindowVisible = false;
          //刷新表格数据
          that.wallpaperCategory();
        })
        .catch((error) => {
          that.$message({
            message: "请求失败",
            type: "error",
            duration: 1500,
          });
        });
    },
  },
  mounted() {
    this.initTableData();
  },
};
</script>

<style>
.around {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32%;
  height: 100px;
}
.white {
  color: white;
}
</style>