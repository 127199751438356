import request from "../utils/request";

export function getAll(currentPage,pageSize){
    return request({
        url: '/qiniu/getAll?page='+currentPage+'&limit='+pageSize,
        method: 'get'
    })
}

export function deleteOne(id){
    return request({
        url: '/qiniu/delete?id='+id,
        method: 'delete'
    })
}

export function update(data){
    return request({
        url: '/qiniu/update',
        method: 'post',
        data: data
    })
}

export function save(data){
    return request({
        url: '/qiniu/save',
        method: 'post',
        data: data
    })
}

export function flushCache(){
    return request({
        url: '/qiniu/flushCache',
        method: 'post'
    })
}



export function getOne(id){
    return request({
        url: '/qiniu/getOne?id='+id,
        method: 'get'
    })
}

