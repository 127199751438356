<template>
<el-aside :width="isCollapse ?'64px' : '200px'" class="myAside" >
    <el-menu
      :collapse="isCollapse"
      style="height:100vh"
      default-active="2"
      class="el-menu-vertical-demo"
      router="true">
        <div class="header">
          <img src="../assets/flag.png" alt="" style="width: 30px;margin-right: 5px;">
          <b style="color: rgba(0, 0, 0, 0.7)" >青枫后台</b>
        </div>
      <el-menu-item index="/home">
        <i class="el-icon-s-home"></i>
        <span slot="title">控制台</span>
      </el-menu-item>
      <el-menu-item v-show="role.indexOf('developer') != -1" index="/baseManagement">
        <i class="el-icon-s-help"></i>
        <span slot="title">基础管理</span>
      </el-menu-item>
      <el-menu-item v-show="role.indexOf('developer') != -1" index="/useradmin">
        <i class="el-icon-s-custom"></i>
        <span slot="title">账号管理</span>
      </el-menu-item>
      
     
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span>壁纸管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/wallpaper/WallpaperPhoto"><i class="el-icon-s-marketing"></i>图片管理</el-menu-item>
          <el-menu-item index="/wallpaper/upload"><i class="el-icon-s-promotion"></i>上传壁纸</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="ava">
        <template slot="title">
          <i class="el-icon-orange"></i>
          <span>头像管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/profile/ProfilePhoto"><i class="el-icon-s-marketing"></i>图片管理</el-menu-item>
          <el-menu-item index="/profile/upload"><i class="el-icon-s-promotion"></i>上传头像</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item index="/category">
        <i class="el-icon-collection-tag"></i>
        <span slot="title">分类管理</span>
      </el-menu-item>
      <el-menu-item  index="/wxuser">
        <i class="el-icon-user"></i>
        <span slot="title">微信用户</span>
      </el-menu-item>
      <el-menu-item index="/feedback">
        <i class="el-icon-chat-dot-square"></i>
        <span slot="title">用户反馈</span>
      </el-menu-item>
      
      <el-menu-item v-show="role.indexOf('developer') != -1" index="/setting">
        <i class="el-icon-setting"></i>
        <span slot="title">参数配置</span>
      </el-menu-item>

      
    </el-menu> 
</el-aside>
</template>

<script>
  export default {
    methods: {
    
    },
    data(){
      return {
        isCollapse: false,
        role: []
      }
    },
    created(){
      let rol = localStorage.getItem("role")
      if(typeof(rol) != "undefined" && rol != '' && rol != null ){
        this.role = rol;
      }
    }
  }
</script>
<style>
    .myAside{
      border: none;
      /* box-shadow: 0 1px 8px 0 rgba(21, 148, 203, 0.3), 0 2px 13px 0 rgba(165,200,213,.41); */
    }
    .header{
      height: 60px; 
      line-height: 60px; 
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'SimSun';
    }
</style>



