<template>
    <div>
        <div style="margin-bottom:30px;text-align: center;">
            <span style="color:rgba(0,0,0,0.6);margin: 0 5px;">点击卡片，进入分类下的图片</span>
        </div>
     
        <el-row :gutter="20" id="row">
            <el-col :span="6" class="col" v-for="(item,index) in categoryList" :key="index">
                <el-card shadow="always" class="card"  @click.native="enterPhotoWindow(item.cid)">{{ item.title }}</el-card>
            </el-col>
        </el-row>
        
    </div>
     
</template>
<script>
import {getTitle} from '../../api/wallpaperPhoto'

export default{
    name: 'wallpaperPhoto',
    data(){
        return {
            categoryList:[]
        }
    },
    methods:{
        //获取壁纸分类名称
        getTitleInfo(){
            var that = this;
            getTitle().then(res=>{
                if(res.data.status){
                    that.categoryList = res.data.data
                }
            }).catch(error=>{
                that.$message({
                    message: "请求失败",
                    type: 'error',
                    duration: 1500
                });
            })
        },
        enterPhotoWindow(cid){
            this.$router.push({path:'/wallpaper/wallpaperPhotoList',query: {cid:cid}})
        }
    },
    mounted(){
        this.getTitleInfo()
    }
}
</script>
<style scoped>
    .el-card-body{
        margin: 30px;
    }
    .col{
        margin-bottom: 20px;
    }
    .card{
        text-align:center;
        cursor: pointer;
    }
    .card:hover{
        color: cadetblue;
    }

</style>