<template>
    <div>
        <div style="float:right">
            <el-button type="text" round @click.native="showAddDialog()" style="font-weight:bold">添加友情小程序</el-button>
        </div>
            <el-table
            :data="tableData"
            style="width: 100%">
                <el-table-column
                prop="id"
                label="ID"
                width="50">
                </el-table-column>

                <el-table-column
                prop="icon"
                align="center"
                width="300"
                label="图片">
                    <template slot-scope="scope">
                        <el-image
                            :src="scope.row.icon"
                            fit="cover"
                            :preview-src-list="scope.row.icon"
                            style="width: 100px; height: 100px"
                        ></el-image>
                    </template>
                </el-table-column>

                 <el-table-column
                prop="name"
                align="center"
                width="100"
                label="小程序名称">
                </el-table-column>

                <el-table-column
                prop="appid"
                align="center"
                width="150"
                label="小程序APPID">
                </el-table-column>

                <el-table-column
                prop="path"
                align="center"
                width="150"
                label="跳转页面路径">
                </el-table-column>
                
                <el-table-column
                prop="description"
                align="center"
                width="150"
                label="描述">
                </el-table-column>


                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        @click="showEditDialog(scope.row.id)">编辑</el-button>
                        <el-button
                        size="mini"
                        type="danger"
                        @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 编辑视图 -->
            <el-dialog title="修改友情小程序" width="950px" :visible.sync="editWindowVisible" append-to-body @close="editDialogClosed">
                <el-form label-width="140px"  :model="editForm" :rules="editFormRules" ref="editFormRef" >
                    <el-form-item label="小程序图标" prop="icon" >
                        <el-upload
                            class="avatar-uploader"
                            :action="this.uploadURL"
                            :show-file-list="false"
                            :headers="headers"
                            accept=".jpg , .png"
                            :on-progress="handleUploading"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="editForm.icon" :src="editForm.icon" class="friendshipIcon">
                            <i v-else class="el-icon-plus avatar-uploader-icon-class"></i>
                        </el-upload>
                        <div class="tip">推荐上传1:1比例的图片，点击更新图片</div>
                    </el-form-item>

                    <el-form-item label="小程序名称" prop="name">
                        <el-input v-model="editForm.name" maxlength="7"></el-input>
                        <div class="tip">最多6个字符</div>
                    </el-form-item>

                    <el-form-item label="小程序APPID" prop="appid">
                        <el-input v-model="editForm.appid"></el-input>
                    </el-form-item>

                    <el-form-item label="打开小程序路径" prop="path" >
                        <el-input v-model="editForm.path" style="width:50%"></el-input>
                        <div class="tip"> 默认路径：/pages/index/index</div>
                    </el-form-item>

                    <el-form-item label="描述信息" prop="description">
                        <el-input v-model="editForm.description" maxlength="9"></el-input>
                        <div class="tip">最多9个字符</div>
                    </el-form-item>
                   
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitEditForm">提交</el-button>
                    <el-button @click="editWindowVisible = false">取消</el-button>
                </span>
            </el-dialog>

            <!-- 添加视图 -->
            <el-dialog title="添加友情小程序" width="950px" :visible.sync="addWindowVisible" append-to-body @close="addDialogClosed">
                <el-form label-width="140px"  :model="addForm" :rules="addFormRules" ref="addFormRef" >
                    <el-form-item label="小程序图标" prop="icon" >
                        <el-upload
                            class="avatar-uploader"
                            :action="this.uploadURL"
                            :show-file-list="false"
                            :headers="headers"
                            accept=".jpg , .png"
                            :on-progress="handleUploading"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="addForm.icon" :src="addForm.icon" class="friendshipIcon">
                            <i v-else class="el-icon-plus avatar-uploader-icon-class"></i>
                        </el-upload>
                        <div class="tip">推荐上传1:1比例的图片，点击更新图片</div>
                    </el-form-item>

                    <el-form-item label="小程序名称" prop="name">
                        <el-input v-model="addForm.name" maxlength="7"></el-input>
                        <div class="tip">最多6个字符</div>
                    </el-form-item>

                    <el-form-item label="小程序APPID" prop="appid">
                        <el-input v-model="addForm.appid"></el-input>
                    </el-form-item>

                    <el-form-item label="打开小程序路径" prop="path" >
                        <el-input v-model="addForm.path" style="width:50%"></el-input>
                        <div class="tip"> 默认路径：/pages/index/index</div>
                    </el-form-item>

                    <el-form-item label="描述信息" prop="description">
                        <el-input v-model="addForm.description" maxlength="9"></el-input>
                        <div class="tip">最多9个字符</div>
                    </el-form-item>
                   
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitAddForm">提交</el-button>
                    <el-button @click="addWindowVisible = false">取消</el-button>
                </span>
            </el-dialog>

            <!-- 分页视图 -->
            <div class="block" style="text-align: center;margin-top: 15px;">
                <el-pagination
                    layout="prev, pager, next"
                    :page-size="pageSize"
                    :current-page="currentPage"
                    @current-change="handlePageChange"
                    :total="total">
                </el-pagination>
            </div>
    </div>
</template>
<script>
    import {getAll,deleteFriendship,getOne,updateFriendship,addFriendship} from '@/api/friendship.js'
    import request from '../../utils/request'
    export default {
        name: 'friendship',
        data(){
            return {
                uploadURL:'',
                //修改表单的数据项
                editForm: {
                    path:'',
                    icon:'',
                    appid:'',
                    description:''
                },
                //添加功能的表单数据项
                addForm: {
                    path:'',
                    icon:'',
                    appid:'',
                    description:''
                },
                //修改表单的验证规则对象
                editFormRules:{
                    name:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入小程序名称',trigger:'blur'
                        } 
                    ],
                    path:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入页面路径',trigger:'blur'
                        } 
                    ],
                    appid:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入APPID',trigger:'blur'
                        } 
                    ],
                    description:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入描述信息',trigger:'blur'
                        } 
                    ]
                },
                addFormRules:{
                    name:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入小程序名称',trigger:'blur'
                        } 
                    ],
                    path:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入页面路径',trigger:'blur'
                        } 
                    ],
                    appid:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入APPID',trigger:'blur'
                        } 
                    ],
                    description:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入描述信息',trigger:'blur'
                        } 
                    ]
                },
                //控制修改对话框的显示与隐藏
                editWindowVisible: false,
                addWindowVisible:false,
                addOperate: true,
                //页大小
                pageSize: 5,
                //当前页
                currentPage: 1,
                //表格数据总和
                total: 0,
                //表格数据列表
                tableData: [],
                headers:{
                    token:""
                }
            }
            
        },
        methods:{
            //当页数发生改变时，触发的函数
            handlePageChange(val){
                
            },
            //进入添加轮播图
            enterAddBanner(){
                this.$router.push({path:'/addBanner'})
            },
            //获取轮播图信息
            getTableData(){
                var that = this;
                getAll().then(res=>{
                    that.total = res.data.total
                    that.tableData = res.data.data;
                }).catch(error=>{
                    that.$message({
                            message: "请求数据失败",
                            type: 'error',
                            duration: 1500
                        });
                })
            },
            //监听修改表单的关闭事件
            editDialogClosed(){
                this.$refs.editFormRef.resetFields();
            },
             //监听添加表单的关闭事件
             addDialogClosed(){
                this.$refs.addFormRef.resetFields();
            },
            //点击编辑事件函数
            showEditDialog(id){
                var that = this;
                getOne(id).then(res=>{
                    console.log("editres:");
                    console.log(res)
                    that.editForm = res.data.data
                })
                this.editWindowVisible = true;
            },
            //点击添加事件函数
            showAddDialog(){
                this.addForm =  {
                    path:'',
                    icon:'',
                    appid:'',
                    description:''
                };
                this.addWindowVisible = true;
            },
            //根据ID删除壁纸分类
            handleDelete(id){
                var that = this;
                deleteFriendship(id).then(res=>{
                    if(res.data.status){
                        this.$message({
                            message: res.data.msg,
                            type: 'success',
                            duration: 1000
                        });
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'error',
                            duration: 1000
                        });
                    }
                    //刷新表格数据
                    that.getTableData();
                })
            },
            
            //提交修改表单触发事件
            submitEditForm(){
                var that = this;
                this.$refs['editFormRef'].validate((valid) => {
                    //判断表单是否填写完整
                    if (valid) {
                        if(this.editForm.icon == "" || this.editForm.icon == null){
                            this.$message.error('请上传轮播图图片');
                            return;
                        }
                        updateFriendship(this.editForm).then(res=>{
                            this.$message.success('修改成功');
                            this.getTableData();
                            this.editWindowVisible = false;
                        }).catch(error=>{
                            that.$message({
                                message: "请求失败",
                                type: 'error',
                                duration: 1500
                            });
                        })
                    } else {
                        this.$message.error('请正确填写表单');
                        return false;
                    }
                });
               
            },

             //提交添加表单触发事件
             submitAddForm(){
                var that = this;
                this.$refs['addFormRef'].validate((valid) => {
                    //判断表单是否填写完整
                    if (valid) {
                        if(this.addForm.icon == "" || this.addForm.icon == null){
                            this.$message.error('请上传轮播图图片');
                            return;
                        }
                        addFriendship(this.addForm).then(res=>{
                            this.$message.success('修改成功');
                            this.getTableData();
                            this.addWindowVisible = false;
                        }).catch(error=>{
                            that.$message({
                                message: "请求失败",
                                type: 'error',
                                duration: 1500
                            });
                        })
                    } else {
                        this.$message.error('请正确填写表单');
                        return false;
                    }
                });
               
            },

            //上传轮播图成功后的回调函数
            handleAvatarSuccess(res, file) {
                this.loading.close()
                this.editForm.icon = res.data;
                this.addForm.icon = res.data;
                this.$message.success('上传成功');
            },   
            //上传轮播图之前的处理函数
            beforeAvatarUpload(file) {
                const isLt20M = file.size / 1024 / 1024 < 20;
                if (!isLt20M) {
                this.$message.error('上传头像图片大小不能超过 20MB!');
                }
                return isLt20M;
            },
            handleUploading(event, file, fileList){
                 //显示加载动画
                this.loading = this.$loading({
                    lock: true,
                    text: '文件上传中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
            },
        },
        mounted(){
            this.getTableData();
            //获取接口基地址
            this.uploadURL = request.defaults.baseURL + "/base/uploadImg";
            //获取当前的token
            var token = localStorage.getItem("token");
            this.headers.token = token;
        }
    }
</script>

<style>
    .around{
        display: flex; 
        align-items: center;
        justify-content: center;
        width: 32%;
        height: 100px;
    }
    .white{
        color: white;
    }
    .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon-class {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .friendshipIcon {
    width: 100px;
    height: 100px;
    display: block;
  }
  .tip{
    width: 500px; 
    color:#afb9be;
    text-align: left;
  }
</style>