<template>
   <div>
    <el-page-header
        @back="goBack"
        style="color: rgb(64, 158, 255);"
        title="返回轮播图列表"
      ></el-page-header>

    
    <el-form :model="addForm" ref="addFormRef" label-width="120px" style="margin-top: 30px;">
        <el-form-item label="序号" prop="number">
            <el-input v-model="addForm.number" style="width: 50%;" type="number"></el-input>
            <div class="tip">序号越小，排列越前</div>
        </el-form-item>
        <el-form-item label="轮播图图片" prop="imgpath" style="width: 120px;">
            <el-upload
                class="avatar-uploader"
                :action="this.uploadURL"
                :show-file-list="false"
                :headers="headers"
                accept=".jpg , .png"
                :on-progress="handleUploading"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <img v-if="addForm.imgpath" :src="addForm.imgpath" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="tip">图片尺寸推荐使用4:1比例</div>
        </el-form-item>
        <el-form-item label="描述信息" prop="description">
            <el-input v-model="addForm.description"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
            <el-radio v-model="addForm.status" :label="true" value="true">显示</el-radio>
            <el-radio v-model="addForm.status" :label="false" value="false">隐藏</el-radio>
        </el-form-item>
        <el-form-item label="类型" prop="type">
            <el-radio v-model="addForm.type" label="0" >普通</el-radio>
            <el-radio v-model="addForm.type" label="1" >跳转其他小程序</el-radio>
        </el-form-item>
        <el-form-item label="小程序APPID" prop="appid" v-if="addForm.type == 1">
            <el-input v-model="addForm.appid"></el-input>
        </el-form-item>
        <el-form-item label="打开小程序路径" prop="appletPath" v-if="addForm.type == 1">
            <el-input v-model="addForm.appletPath" style="width:50%"></el-input>
            <div class="tip">默认路径：pages/index/index</div>
        </el-form-item>
        <el-form-item label="">
            <el-button type="success" @click="sumbitForm">提交</el-button>
        </el-form-item>
    </el-form>
   </div>
</template>
<script>
    import {addBanner} from '@/api/banner.js'
    import request from '../../utils/request'

    export default {
        name: 'addBanner',
        data(){
            return {
                addForm:{
                    type:'0',
                    description:'',
                    status:true,
                    imgpath:'',
                    appid:''
                },
                uploadURL:'',
                headers:{
                    token:""
                },
                loading:{}
            }
        },
        methods:{
            //上传轮播图成功后的回调函数
            handleAvatarSuccess(res, file) {
                this.loading.close()
                this.addForm.imgpath = res.data;
                this.$message.success('上传成功');
            },   
            //上传轮播图之前的处理函数
            beforeAvatarUpload(file) {
                const isLt20M = file.size / 1024 / 1024 < 20;
                if (!isLt20M) {
                this.$message.error('上传头像图片大小不能超过 20MB!');
                }
                return isLt20M;
            },
            handleUploading(event, file, fileList){
                 //显示加载动画
                this.loading = this.$loading({
                    lock: true,
                    text: '文件上传中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
            },
            //返回轮播图列表
            goBack(){
                this.$router.push("/baseManagement");
            },
            //提交表单
            sumbitForm(){
                if(this.addForm.imgpath == ""){
                    this.$message.error('请上传轮播图');
                    return;
                }
                if(this.addForm.type == "1" && this.addForm.appid == ''){
                    this.$message.error('请输入小程序APPID');
                    return;
                }
                if(this.addForm.type == "1" &&  this.addForm.appletPath == ''){
                    this.$message.error('请输入小程序页面路径');
                    return;
                }
                addBanner(this.addForm).then(res=>{
                    console.log(res)
                    this.$message.success('提交成功');
                    this.$router.push("/baseManagement");
                }).catch(error=>{
                    that.$message({
                        message: "请求失败",
                        type: 'error',
                        duration: 1500
                    });
                })
            }
        },
        created(){
            //获取当前登录的用户信息
            let user = JSON.parse(localStorage.getItem("user"));
            //如果没有用户信息，则返回登录页
            if(typeof(user) == 'undefined' || user == null || user.id == null || user.id == ''){
                this.$message.warning("请重新登录！")
                this.logout()
                return;
            }
            //获取接口基地址
            this.uploadURL = request.defaults.baseURL + "/base/uploadImg";
            //获取当前的token
            var token = localStorage.getItem("token");
            this.headers.token = token;
        }
    }
</script>

<style>
   .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 720px;
    height: 180px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width:  720px;
    height: 180px;
    display: block;
  }

  .tip{
    width: 500px; 
    color:#afb9be;
    text-align: left;
  }
</style>