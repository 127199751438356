<template>
    <div style="display: flex;align-items: center;">
        <el-input
        type="textarea"
        placeholder="请输入内容"
        v-model="content"
        style="width:50%"
        maxlength="30"
        show-word-limit
        ></el-input>

        <el-button type="success" @click="sumbitNotice" style="margin: 20px 40px;">提交</el-button>
    </div>
</template>
<script>
import { getNotice,saveNotice } from '@/api/notice'

export default {
   name: 'notice',
   data(){
    return {
        //公告内容
        content:''
    }
   },
   methods:{
       //保存公告信息
       sumbitNotice(){
            if(this.content == null || this.content == ''){
                this.$message({
                    message: "请输入公告信息",
                    type: 'error',
                    duration: 1500
                });
                return;
            }
            saveNotice(this.content).then(res=>{
                this.$message({
                    message: "保存成功",
                    type: 'success',
                    duration: 1500
                });
            }).catch(error=>{
                this.$message({
                    message: "请求失败",
                    type: 'error',
                    duration: 1500
                });
            })
       }
   },
   mounted(){
    //获取公告信息
    getNotice().then(res=>{
        this.content = res.data.data == null ? '' : res.data.data;
    }).catch(error=>{
        this.$message({
                message: "请求数据失败",
                type: 'error',
                duration: 1500
            });
    })
   }

}
</script>

<style scoped>

</style>
