import request from "../utils/request";

export function UserLogin(user) {
    return request({
        url: '/login',
        method: 'post',
        data:user
    })
}

export function UserLogout(user) {
    return request({
        url: '/logout',
        method: 'post',
        data:user
    })
}

export function getAllUser(){
    return request({
        url: '/user/getAll',
        method: 'get'
    })
}

export function getOneUser(id){
    return request({
        url: '/user/getById?id='+id,
        method: 'get'
    })
}

export function updateUser(fromData){
    return request({
        url: '/user/update',
        method: 'put',
        data:fromData
    })
}

//修改个人密码
export function updateUserPassword(id,oldPassword,password){
    return request({
        url: '/user/updatePassword',
        method: 'post',
        data:{"id":id,"oldPassword": oldPassword,"password":password}
    })
}

//管理员修改用户密码
export function updatePassword(id,newpassword){
    return request({
        url: '/user/updateUserPassword',
        method: 'post',
        data:{"id":id,"newpassword":newpassword}
    })
}



export function addUser(addForm){
    return request({
        url: '/user/add',
        method: 'post',
        data:addForm
    })
}

export function deleteUser(id){
    return request({
        url: '/user/delete?id='+id,
        method: 'delete'
    })
}

export function logout(id){
    return request({
        url: '/logout',
        method: 'post',
        data:{"id":id}
    })
}

