<template>
    <div>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>基本信息设置</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="showAddDialog">添加配置项</el-button>
            </div>
            <el-checkbox-group v-model="checkedArray" size="middle">
            <div v-for="(item,index) in wxoption" class="option_item">
                <span class="optionkey">
                    <el-tag type="success" size="middle">{{ item.description }}</el-tag>
                    <el-tooltip v-show="item.remark != '' && item.remark != null" class="item" 
                    effect="dark" :content="item.remark" placement="top-start" style="margin-left: 10px;">
                        <el-link type="primary">查看备注</el-link>
                    </el-tooltip>
                </span> 
                <div style="display: flex;align-items: center;justify-content: space-between;">
                    <!-- 输入框 -->
                    <el-input
                        style="width: 40%;display: inline-block;"
                        placeholder="请输入内容"
                        :show-password="item.ok_secret"
                        v-model="item.value"
                        size="medium"
                    >
                    </el-input>
                    <!-- 复选框 -->
                    <el-checkbox size="middle" :label="item.id" >&nbsp;</el-checkbox>
                </div>
                
                <!-- 分割符 -->
                <el-divider></el-divider>
            </div>
        </el-checkbox-group>
            <div class="btn_box">
                <el-button type="primary" @click="ensureSaveOption">保存配置</el-button>
                <el-button type="danger" @click="ensureDeleteOption">删除选中配置</el-button>
            </div>
        </el-card>

        <!-- 添加视图 -->
        <el-dialog  title="添加配置项" :visible.sync="addWindowVisible" append-to-body @close="addDialogClosed">
                <el-form label-width="100px" :rules="addFormRules" :model="addForm" ref="addFormRef" >
                    <el-form-item label="配置项名称" prop="name">
                        <el-input v-model="addForm.name" placeholder="使用字母表示" class="item_input"></el-input>
                    </el-form-item>
                    <el-form-item label="配置项值" prop="value">
                        <el-input v-model="addForm.value" class="item_input" placeholder="配置项的取值"></el-input>
                    </el-form-item>
                    <el-form-item label="配置项默认值" prop="default_val">
                        <el-input v-model="addForm.default_val"  class="item_input"></el-input>
                    </el-form-item>
                    <el-form-item label="配置项描述" prop="description">
                        <el-input v-model="addForm.description" placeholder="必填项，如：微信APPID" class="item_input"></el-input>
                    </el-form-item>
                    <el-form-item label="是否保密" prop="ok_secret">
                        <el-radio-group v-model="addForm.ok_secret">
                            <el-radio :label="true" value=true >是</el-radio>
                            <el-radio :label="false" value=false>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="备注信息" prop="remark">
                        <el-input v-model="addForm.remark" class="item_input"></el-input>
                    </el-form-item>

                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="resetForm('addFormRef')" type="info">重置</el-button>
                    <el-button type="primary" @click="submitAddForm" >提交</el-button>
                    <el-button @click="addWindowVisible = false">取消</el-button>
                </span>
        </el-dialog>
    </div>
    
</template>
<script>
import {getWXOptions,updateWXOptions,addWXOptions,deletesOptions} from '../../api/setting'
export default {
    name: 'WXSetting',
    inject:['reload'],
    data(){
        var notNull = (rule, value, callback) => {
            if( value === null || value.trim() === ''){
                callback(new Error('请输入内容'));
            }else{
                callback()
            }
        };
        return{
            wxoption:[],
            addWindowVisible: false,
            addForm:{ok_secret:false},
            selected: 'option2',
            checkedArray:[],
            addFormRules:{
                name: [
                    { required: true, message: "请输入名称", trigger: "blur" },
                    { validator: notNull, trigger: 'blur' }
                ],
                value: [
                    { required: true, message: "请输入配置项值", trigger: "blur" },
                    { validator: notNull, trigger: 'blur' }
                ],
                description: [
                    { required: true, message: "请输入描述", trigger: "blur" },
                    { validator: notNull, trigger: 'blur' }
                ]
            },
        }
    },
    methods:{
        //弹出确认框提醒用户，是否保存配置
        ensureSaveOption(){
            this.$confirm('确认保存以上配置吗', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                callback: this.handleSaveOption
            })
        },
        //弹出确认框提醒用户，是否删除选中的配置
        ensureDeleteOption(){
            this.$confirm('确认删除选择的配置吗', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'danger',
                callback: this.handleDeleteOption
            })
        },
        //批量删除配置项
        handleDeleteOption(action,instance){
            let that = this;
            if(action === 'cancel' || action == 'close') return;
            //发起删除请求
            deletesOptions(this.checkedArray).then(res=>{
                if(res.data.status){
                    this.$notify({
                        title: '提示',
                        message: '删除成功',
                        type: 'success'
                    });
                }else{
                    this.$notify.error({
                        title: '错误',
                        message: '删除失败，请稍后重试',
                    });
                }
            }).catch(err=>{
                this.$message.error("请求失败")
            }).finally(()=>{
                //重新加载数据
                that.initPageData()
            })
        },
        //发起请求，保存配置
        handleSaveOption(action,instance){
            if(action === 'cancel' || action == 'close') return;
            updateWXOptions(this.wxoption).then(res=>{
                if(res.data.status){
                    this.$notify({
                        title: '提示',
                        message: '保存成功',
                        type: 'success'
                    });
                }else{
                    this.$notify.error({
                        title: '错误',
                        message: '保存失败，请稍后重试',
                    });
                }
            }).catch(err=>{
                this.$message.error("请求失败")
            }).finally(()=>{
                that.initPageData()
                that.addWindowVisible = false
            })
        },
        //关闭添加窗口
        addDialogClosed(){
            this.addWindowVisible = false;
        },
        //显示添加弹窗
        showAddDialog(){
            this.addForm = {ok_secret:false};
            this.addWindowVisible = true;
        },
        //重置添加表单
        resetForm(addFormRef) {
            this.$refs[addFormRef].resetFields();
        },
        //初始化页面数据
        initPageData(){
            let that = this;
            getWXOptions().then(res=>{
                that.wxoption = res.data.data;
            }).catch(err=>{
                this.$message.error("请求失败")
            })
        },
        //提交添加表单
        submitAddForm(){
            let that = this;
            this.$refs["addFormRef"].validate(valid =>{
                //检查表单是否正确填写
                if(valid) {
                    let user = JSON.parse(localStorage.getItem("user"));
                    addWXOptions(that.addForm,user.username).then(res=>{
                        if(res.data.status){
                            this.$message.success("添加成功^_^")
                        }else{
                            this.$message.error("添加失败，"+res.data.msg)
                        }
                    }).catch(err=>{
                        this.$message.error("请求失败，请联系管理员")
                    }).finally(()=>{
                        that.initPageData()
                        that.addWindowVisible = false
                    })
                } else {
                    this.$message.warning('请正确填写表单')
                    return false;
                }
            })
            
        }

    },
    //构子函数
    mounted(){
        this.initPageData()
    }
}
</script>
<style scoped>
.option_item{
    margin-bottom: 20px;
    
}
.option_item .optionkey{
    font-family: "Microsoft YaHei";
    display: block;
    margin-bottom: 10px;
}

.item_input{
    display: inline-block;
    width: 70%;
}
</style>
