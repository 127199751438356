<template>
<svg t="1683084955479" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6405" width="200" height="200"><path d="M853.333333 725.333333H170.666667a170.666667 170.666667 0 0 1-85.333334-318.293333 298.666667 298.666667 0 0 1 520.533334-179.2A210.773333 210.773333 0 0 1 682.666667 213.333333a213.333333 213.333333 0 0 1 209.92 175.36A170.666667 170.666667 0 0 1 853.333333 725.333333zM384 213.333333a213.333333 213.333333 0 0 0-213.333333 213.333334v6.4a42.666667 42.666667 0 0 1-28.586667 42.666666A85.333333 85.333333 0 0 0 170.666667 640h682.666666a85.333333 85.333333 0 0 0 0-170.666667 42.666667 42.666667 0 0 1-42.666666-42.666666 128 128 0 0 0-128-128 125.013333 125.013333 0 0 0-66.133334 19.2 42.666667 42.666667 0 0 1-57.173333-12.373334A213.333333 213.333333 0 0 0 384 213.333333z m512 213.333334z" p-id="6406"></path></svg>
</template>
     
     <script>
     import { defineComponent } from 'vue'
     export default defineComponent({
       name: 'CloudIcon'
     })
     </script>
     
     <style scoped>
     .icon {
       display: inline-block;
       width: 2em;
       height: 2em;
       fill: currentColor;
       vertical-align: middle;
     }
     </style>
     