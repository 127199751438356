<template>
    <div style="display: inline-block; width: 50px; height: 50px;">footer</div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>