<template>
    <div>
        <WechatIcon name="wechat" class="icon-wechat" />
        <iconfont class="icon-wechat"></iconfont>
        <el-tabs type="border-card" >
            <el-tab-pane >
                <span slot="label" style="display: flex;align-items: center;">
                    <Icon name="wechat" /><span style="margin-left: 5px;">微信配置</span>
                </span>
                <wxSetting></wxSetting>
            </el-tab-pane>
            <el-tab-pane>
                <span slot="label" style="display: flex;align-items: center;">
                    <cache-icon></cache-icon> <span style="margin-left: 5px;">缓存配置</span>
                </span>
                <cacheSetting></cacheSetting>
            </el-tab-pane>
            <el-tab-pane label="七牛云配置">
                <span slot="label" style="display: flex;align-items: center;">
                <cloud-icon></cloud-icon> <span style="margin-left: 5px;">七牛云配置</span>
                </span>
                <qiniuSetting></qiniuSetting>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import cacheSetting from './CacheSetting.vue'
import wxSetting from './WXSetting.vue'
import Icon from '../../components/myIcon/Icon.vue'
import CacheIcon from '../../components/myIcon/CacheIcon.vue'
import CloudIcon from '../../components/myIcon/CloudIcon.vue'
import qiniuSetting from './QiniuSetting.vue'
export default {
    name: 'Setting',
    components:{
        cacheSetting,
        wxSetting,
        Icon,
        CacheIcon,
        CloudIcon,
        qiniuSetting
    },
    methods:{
    }
}
</script>
<style scoped>
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
