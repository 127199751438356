<template>
  <el-header class="myheader">
    <span style="margin-right: 10px"><i class="el-icon-s-fold"></i></span>
    <el-breadcrumb separator="/" >
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item >{{ currentPathName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="position: absolute; right: 30px">
      <el-dropdown trigger="click" style="cursor: pointer;" size="medium">
        <span class="el-dropdown-link" style="color: #409eff">
          个人中心<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-edit" @click.native="updatePassword">修改密码</el-dropdown-item>
          <el-dropdown-item icon="el-icon-switch-button" @click.native="handleLogout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-header>
</template>

<script>
export default {
  name: "Header",
  computed: {
    currentPathName() {
      return this.$store.state.currentPathName;
    },
  },
  methods: {
    //处理用户退出事件
    handleLogout(){
     //弹出确认框提醒用户
     this.$confirm('确认退出吗', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        callback: this.Tologout
      })
    },
    updatePassword(){
        this.$router.push("/updatePassword")
    },
    Tologout(action, instance){
      if(action == 'confirm'){
        this.logout()
      }else{
        this.$message.info("已取消退出")
      }
    }

  },
};
</script>

<style>
.myheader {
  box-shadow: 0.1px 0.3px 10px 0.1px #cac8c8;
  width: 100%;
  display: flex;
  align-items: center;
}
</style>