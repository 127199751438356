<template>
    <div>
      <div class="toolBar">
          <el-button
                size="mini"
                type="primary"
                @click="handleAdd()"
                >添加</el-button
        >
        <el-button
            size="mini"
            type="warning"
            @click="handleFlushCache()"
            >刷新缓存</el-button
        >
      </div>
      
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="ID" width="140"> </el-table-column>
  
        <el-table-column prop="accessKey" align="center" label="AccessKey">
        </el-table-column>
  
        <el-table-column prop="secretKey"  align="center" label="SecretKey">
          <template slot-scope="scope">
          <el-row>
              <span >*******************</span>
              <span style="display: none;" >{{ scope.row.secretKey }}</span>
          </el-row>
        </template>
        </el-table-column>

        <el-table-column prop="bucket" align="center" label="存储空间Bucket">
        </el-table-column>
  
        <el-table-column prop="path" align="center" label="图片域名">
        </el-table-column>

        <el-table-column align="center" width="180" label="操作">
          <template slot-scope="scope">

            <el-button
              size="mini"
              type="warning"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >

            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

       <!-- 编辑视图 -->
       <el-dialog :title="title" :visible.sync="WindowVisible" width="70%" append-to-body @close="dialogClosed">
                <el-form label-width="160px" :rules="FormRules" :model="dataForm" ref="FormRef" >
                    <el-form-item label="Id" prop="id" >
                        <el-input v-model="dataForm.id" type="number" :disabled="title=='编辑'" value="dataForm.id" placeholder="ID设置为1才会被系统识别"></el-input>
                    </el-form-item>
                    <el-form-item label="AccessKey" prop="accessKey">
                        <el-input v-model="dataForm.accessKey"></el-input>
                    </el-form-item>
                    <el-form-item label="SecretKey" prop="secretKey">
                      <el-input v-model="dataForm.secretKey" type="password" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="存储空间Bucket" prop="bucket">
                        <el-input v-model="dataForm.bucket"></el-input>
                    </el-form-item>
                    <el-form-item label="图片域名" prop="path">
                        <el-input v-model="dataForm.path"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitDataForm">提交</el-button>
                    <el-button @click="WindowVisible = false">取消</el-button>
                </span>
            </el-dialog>
  
      <!-- 分页视图 -->
      <div class="block" style="text-align: center; margin-top: 15px">
        <el-pagination
          layout="prev, pager, next, jumper"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handlePageChange"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </template>
  <script>
// import {getAll, deleteOne} from '../api/wxuser';
import {getAll,save,update,deleteOne,flushCache} from '@/api/qiniu'
  export default {
    name: "qiniuSetting",
    data() {
      return {
        //控制修改对话框的显示与隐藏
        WindowVisible:false,
        addOperate: true,
        dataForm:{},
        title:"",
        //页大小
        pageSize: 5,
        //当前页
        currentPage: 1,
        //表格数据总和
        total: 0,
        //表单规则
        FormRules:{
                id: [
                    { required: true, message: "请输入ID", trigger: "blur" }
                ],
                accessKey: [
                    { required: true, message: "请输入accessKey", trigger: "blur" }
                ],
                secretKey: [
                    { required: true, message: "请输入secretKey", trigger: "blur" }
                ],
                bucket: [
                    { required: true, message: "请输入bucket", trigger: "blur" }
                ],
                path: [
                    { required: true, message: "请输入图片域名", trigger: "blur" }
                ]
                ,
            },
        //表格数据列表
        tableData: [],
      };
    },
    methods: {
      //当页数发生改变时，触发的函数
      handlePageChange(val) {
        var that = this;
        getAll(`${val}`, this.pageSize).then((res) => {
          that.total = res.data.total;
          that.tableData = res.data.data;
        });
      },
      //删除微信用户
      handleDelete(id){
        deleteOne(id).then(res=>{
            if(res.data.status){
              this.$message.success(res.data.msg);
            }else{
              this.$message.error("删除失败，请稍后重试");
            }
        }).catch(err=>{
          that.$message({
              message: "请求失败",
              type: "error",
              duration: 1500,
            });
        }).finally(()=>this.initTableData())
      },
      //打开编辑窗口
      handleEdit(row){
        this.title = "编辑";
        this.dataForm = row;
        this.WindowVisible = true;
      },
      //打开添加窗口
      handleAdd(){
        this.title = "添加";
        this.dataForm = {};
        this.WindowVisible = true;
      },
      //提交编辑表单
      submitDataForm(){
        var that = this;
        if(this.title == "编辑"){
          console.log(that.dataForm)
          update(that.dataForm).then(res=>{
          if(res.data.code == 200){
            that.$message({
              message: "修改成功",
              type: "success",
              duration: 1000,
            });
          }else{
            that.$message({
              message: res.msg,
              type: "warning",
              duration: 1000,
            });
          }
        }).catch(err=>{
          that.$message({
              message: "请求失败",
              type: "error",
              duration: 1500,
            });
          }).finally(()=>{
            that.initTableData()
            that.WindowVisible = false;
          })
        }else if(that.title == "添加"){
          save(that.dataForm).then(res=>{
          if(res.data.code == 200){
            that.$message({
              message: "添加成功",
              type: "success",
              duration: 1000,
            });
          }else{
            that.$message({
              message: res.data.msg,
              type: "warning",
              duration: 1000,
            });
          }
        }).catch(err=>{
          that.$message({
              message: "请求失败",
              type: "error",
              duration: 1500,
            });
        }).finally(()=>{
          that.initTableData()
          that.WindowVisible = false;
        })
        }
        
      },
      dialogClosed(){
        this.dataForm = {};
      },
      //刷新缓存
      handleFlushCache(){
        var that = this;
        flushCache().then(res=>{
          if(res.data.code == 200){
            that.$message({
              message: "刷新成功",
              type: "success",
              duration: 1200,
            });
          }else{
            that.$message({
              message: "刷新发生异常，请联系管理员",
              type: "error",
              duration: 1200,
            });
          }
        }).catch(err=>{
          that.$message({
              message: "请求失败",
              type: "error",
              duration: 1200,
            });
          })
        },
      //初始化表格数据
      initTableData() {
        let that = this;
        getAll(this.currentPage, this.pageSize)
          .then((res) => {
            that.tableData = res.data.data;
            that.total = res.data.total;
          })
          .catch((error) => {
            that.$message({
              message: "请求失败",
              type: "error",
              duration: 1500,
            });
          });
      },
     
    },
    mounted() {
      this.initTableData();
    },
  };
  </script>
  
  <style>
  .around {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32%;
    height: 100px;
  }
  .white {
    color: white;
  }
  .toolBar{
    margin-bottom: 10px;
  }
  </style>