import request from "../utils/request";

export function getAll(currentPage,pageSize){
    return request({
        url: '/wxuser/getAll?page='+currentPage+'&limit='+pageSize,
        method: 'get'
    })
}

export function deleteOne(id){
    return request({
        url: '/wxuser/deleteOne?id='+id,
        method: 'delete'
    })
}

