import request from "../utils/request";

//获取壁纸的所有分类
export function getTitle() {
    return request({
        url: '/wallcategory/getList',
        method: 'get'
    })
}

//分页获取壁纸图片
export function getWallpaperPhoto(currentPage,pageSize,cid) {
    return request({
        url: '/wallpaper/getWallList?page='+currentPage+'&limit='+pageSize+'&cid='+cid,
        method: 'get'
    })
}

//批量删除图片
export function deleteWallpaperPhoto(batchArr){
    return request({
        url: '/wallpaper/delete',
        data:batchArr,
        method: 'post'
    })
}

//导入网络图片
export function batchNetworkPhoto(formData){
    return request({
        url: '/wallpaper/batchImport',
        data:formData,
        method: 'post'
    })
}

//删除全部图片
export function deleteAllWallpaperPhoto(cid){
    return request({
        url: '/wallpaper/deleteAll?cid='+cid,
        method: 'delete'
    })
}

//上传壁纸图片
export function uploadWallpaperPhoto(formData){
    return request({
        url: '/wallpaper/upload',
        method: 'post',
        data:formData
    })
}