import Vue from 'vue'
import App from './App.vue'
import store from '@/utils/store'
import router from './router/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipBoard from 'vue-clipboard2'
import Vuex from "vuex";
import './assets/global.css'
//引入全局函数
import globalFunction from './global/globalFunction.js'

Vue.use(globalFunction)
Vue.use(ElementUI,{size: "small"});
Vue.use(Vuex)
Vue.use(VueClipBoard)
//使用路由
// Vue.use(VueRouter)
//关闭生产提示
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  store: store,
  router: router
}).$mount('#app')

