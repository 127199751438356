<template>
    <div>
        <div style="float:right">
            <el-button type="text" round @click="showAddDialog" style="font-weight:bold">添加分类</el-button>
        </div>
            <el-table
            :data="tableData"
            style="width: 100%">
                <el-table-column
                prop="cid"
                label="分类ID"
                width="150">
                </el-table-column>

                <el-table-column
                prop="number"
                label="序号"
                width="150">
                    <template slot-scope="scope">
                        <el-badge :value="scope.row.number"  type="primary" class="item"></el-badge>
                    </template>
                </el-table-column>

                <el-table-column
                prop="title"
                align="center"
                label="分类名称">
                </el-table-column>

                <el-table-column
                prop="is_show"
                align="center"
                label="状态"
                width="230">
                    <template slot-scope="scope">
                        <el-tag
                        :type="scope.row.is_show === 0 ? 'primary' : 'success'"
                        disable-transitions>{{scope.row.is_show === 0 ? '隐藏':'显示'}}</el-tag>
                    </template>
                </el-table-column>
                

                <el-table-column align="center" width="180" label="操作">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        @click="showEditDialog(scope.row.cid)">编辑</el-button>
                        <el-button
                        size="mini"
                        type="danger"
                        @click="handleDelete(scope.row.cid)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 编辑视图 -->
            <el-dialog title="修改分类" :visible.sync="windowVisible" append-to-body @close="editDialogClosed">
                <el-form label-width="80px" :rules="editFormRules" :model="editForm" ref="editFormRef" >
                    <el-form-item label="分类ID" >
                        <el-input v-model="editForm.cid" value="editForm.cid" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="序号" prop="number">
                        <el-input v-model="editForm.number" value="editForm.number"></el-input>
                        <div class="tip">序号越小，排列越前</div>
                    </el-form-item>
                    <el-form-item label="分类名称" prop="title">
                        <el-input v-model="editForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-switch
                            v-model="editForm.is_show"
                            active-text="显示"
                            inactive-text="隐藏"
                            active-value="1"
                            inactive-value="0">
                        </el-switch>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitForm">提交</el-button>
                    <el-button @click="windowVisible = false">取消</el-button>
                </span>
            </el-dialog>

            <!-- 添加视图 -->
            <el-dialog title="添加分类" :visible.sync="addWindowVisible" append-to-body @close="addDialogClosed">
               
                <el-form label-width="80px" :rules="addFormRules" :model="addForm" ref="addFormRef" >
                    <el-form-item label="序号"  prop="number">
                        <el-input v-model="addForm.number" value="addForm.number"></el-input>
                        <div class="tip">序号越小，排列越前</div>
                    </el-form-item>
                    
                    <el-form-item label="分类名称" prop="title">
                        <el-input v-model="addForm.title" @blur="addForm.title=$event.target.value.trim()"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitAddForm" :disabled="addForm.title.trim() == ''">提交</el-button>
                    <el-button @click="addWindowVisible = false">取消</el-button>
                </span>
            </el-dialog>

            <!-- 分页视图 -->
            <div class="block" style="text-align: center;margin-top: 15px;">
                <el-pagination
                    layout="prev, pager, next"
                    :page-size="pageSize"
                    :current-page="currentPage"
                    @current-change="handlePageChange"
                    :total="total">
                </el-pagination>
            </div>
    </div>
</template>
<script>
    import {getWallpaperCategory} from '../../api/category'
    import {deleteWallpaperCategory} from '../../api/category'
    import {getWallpaperCategoryByCid} from '../../api/category'
    import {updateWallpaperCategory} from '../../api/category'
    import {addWallpaperCategory} from '../../api/category'

    export default {
        name: 'wallcategory',
        data(){
            return {
                //添加表单的数据项
                addForm:{
                    title:'',
                    number:0
                },
                //修改表单的数据项
                editForm: {
                    title:'',
                    is_show:'',
                    cid:0,
                    number:0
                },
                //修改表单的验证规则对象
                editFormRules:{
                    number:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入序号',trigger:'blur'
                        } 
                    ],
                    title:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入分类名称',trigger:'blur'
                        } 
                    ]
                },
                addFormRules:{
                    number:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入序号',trigger:'blur'
                        } 
                    ],
                    title:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入分类名称',trigger:'blur'
                        } 
                    ]
                },
                //控制修改对话框的显示与隐藏
                windowVisible: false,
                addWindowVisible:false,
                addOperate: true,
                //页大小
                pageSize: 5,
                //当前页
                currentPage: 1,
                //表格数据总和
                total: 0,
                //表格数据列表
                tableData: []
            }
            
        },methods:{
            //当页数发生改变时，触发的函数
            handlePageChange(val){
                var that = this;
                getWallpaperCategory(`${val}`,this.pageSize).then(res=>{
                    that.total = res.data.total
                    that.tableData = res.data.data;
                })
                
            },
            //获取壁纸分类信息
            wallpaperCategory(){
                var that = this;
                getWallpaperCategory(this.currentPage,this.pageSize).then(res=>{
                    that.total = res.data.total
                    that.tableData = res.data.data;
                }).catch(error=>{
                    that.$message({
                            message: "请求数据失败",
                            type: 'error',
                            duration: 1500
                        });
                })
            },
            //监听修改表单的关闭事件
            editDialogClosed(){
                this.$refs.editFormRef.resetFields();
            },
            //监听添加表单的关闭事件
            addDialogClosed(){
                this.$refs.addFormRef.resetFields();
            },
            //显示添加窗口
            showAddDialog(){
                this.addWindowVisible = true;
            },
            //点击编辑事件函数
            showEditDialog(cid){
                var that = this;
                getWallpaperCategoryByCid(cid).then(res=>{
                    that.editForm = res.data.data
                    that.editForm.is_show = res.data.data.is_show + ""
                    
                })
                this.windowVisible = true;
            },
            //根据ID删除壁纸分类
            handleDelete(cid){
                var that = this;
                deleteWallpaperCategory(cid).then(res=>{
                    if(res.data.status){
                        this.$message({
                            message: res.data.msg,
                            type: 'success',
                            duration: 1000
                        });
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'error',
                            duration: 1000
                        });
                    }
                    //刷新表格数据
                    that.wallpaperCategory();
                })
            },
            //提交添加表单
            submitAddForm(){
                var that = this;
                this.$refs['addFormRef'].validate((valid) => {
                    //判断表单是否填写完整
                    if (valid) {
                        addWallpaperCategory(this.addForm).then(res=>{
                    if(res.data.status){
                        this.$message({
                            message: res.data.msg,
                            type: 'success',
                            duration: 1000
                        });
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'error',
                            duration: 1000
                        });
                    }
                    //关闭弹窗
                    this.addWindowVisible = false;
                    //刷新表格数据
                    that.wallpaperCategory();
                }).catch(error=>{
                    that.$message({
                            message: "请求失败",
                            type: 'error',
                            duration: 1500
                        });
                })
                    } else {
                        this.$message.error('请正确填写表单');
                        return false;
                    }
              });

              
            },
            //提交修改表单触发事件
            submitForm(){
                var that = this;

                this.$refs['editFormRef'].validate((valid) => {
                    //判断表单是否填写完整
                    if (valid) {
                        updateWallpaperCategory(this.editForm).then(res=>{
                            if(res.data.status){
                                this.$message({
                                    message: res.data.msg,
                                    type: 'success',
                                    duration: 1000
                                });
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'error',
                                    duration: 1000
                                });
                            }
                            //关闭弹窗
                            this.windowVisible = false;
                            //刷新表格数据
                            that.wallpaperCategory();
                            }).catch(error=>{
                                that.$message({
                                        message: "请求失败",
                                        type: 'error',
                                        duration: 1500
                                    });
                         })
                    } else {
                        this.$message.error('请正确填写表单');
                        return false;
                    }
              });

              
                
               
            }
        },
        mounted(){
            this.wallpaperCategory();
        }
    }
</script>

<style>
    .around{
        display: flex; 
        align-items: center;
        justify-content: center;
        width: 32%;
        height: 100px;
    }
    .white{
        color: white;
    }
    .tip{
        width: 500px; 
        color:#afb9be;
        text-align: left;
    }
</style>