import axios from 'axios'
import router from '@/router';
import ElementUI from 'element-ui';
const request = axios.create({
    baseURL: 'https://aa.lishihuge.xyz/web',
    timeout: 50000
})


// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    var token = localStorage.getItem("token");
    if (typeof(token) != "undefined" && token != '' && token != null){
        config.headers['token'] = token;  // 设置请求头
    }
    return config
}, error => {
    return Promise.reject(error)
});

var that = this;
// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response;
        let code = response.data.code;
        let msg = response.data.msg;
        //未认证的处理办法：返回登录页
        if (code == 401){
            ElementUI.Message.error("认证失败，请您重新登录")
            router.push('/login')
            return res;
        }
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        return Promise.reject(error)
    }
)


export default request