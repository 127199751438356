import request from "../utils/request";

//清除全局缓存
export function flushdb() {
    return request({
        url: '/flushdb',
        method: 'delete'
    })
}

//清除头像缓存
export function clearProfileCache() {
    return request({
        url: '/clearProfileCache',
        method: 'delete'
    })
}

//清除壁纸缓存
export function clearWallpaperCache() {
    return request({
        url: '/clearWallpaperCache',
        method: 'delete'
    })
}

//清除壁纸缓存
export function updateWallCategoryCache() {
    return request({
        url: '/updateWallCategoryCache',
        method: 'post'
    })
}

//清除壁纸缓存
export function updateProfileCategoryCache() {
    return request({
        url: '/updateProfileCategoryCache',
        method: 'post'
    })
}

//获取微信配置参数
export function getWXOptions(){
    return request({
        url: '/wxoption/getAll',
        method: 'get'
    })
}

//修改微信配置参数
export function updateWXOptions(wxoption){
    return request({
        url: '/wxoption/update',
        data:{
            "wxoptions":wxoption
        },
        method: 'put'
    })
}

//添加微信配置项
export function addWXOptions(wxoption,username){
    return request({
        url: '/wxoption/add/'+username,
        data:wxoption,
        method: 'post'
    })
}

//批量删除微信配置项
export function deletesOptions(idList){
    return request({
        url: '/wxoption/deletes',
        data:idList,
        method: 'post'
    })
}