<template>
    <div>
      <el-tabs  style="height: 100%;">
        <el-tab-pane label="轮播图管理"><banner></banner></el-tab-pane>
        <el-tab-pane label="友情小程序"><friendship></friendship></el-tab-pane>
        <el-tab-pane label="公告管理"><notice></notice></el-tab-pane>
      </el-tabs>
    </div>
</template>
        
    <script>
        import banner from '../views/banner/Banner.vue';
        import notice from '../views/notice/Notice.vue';
        import friendship from '../views/friendship/Friendship.vue';
        export default {
            name: 'BaseManagement',
            components:{
              banner,
              notice,
              friendship
            },
            data(){
                return {
                  
                }
            }
        }
    </script>
        
    <style>
    
    </style>