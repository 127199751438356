import request from "../utils/request";

//获取头像的所有分类
export function getTitle() {
    return request({
        url: '/profileCategory/getList',
        method: 'get'
    })
}

//分页获取头像图片
export function getProfilePhoto(currentPage,pageSize,cid) {
    return request({
        url: '/profile/getProList?page='+currentPage+'&limit='+pageSize+'&cid='+cid,
        method: 'get'
    })
}

//导入网络图片
export function batchNetworkPhoto(formData){
    return request({
        url: '/profile/batchImport',
        data:formData,
        method: 'post'
    })
}

//批量删除图片
export function deleteProfilePhoto(batchArr){
    return request({
        url: '/profile/delete',
        data:batchArr,
        method: 'post'
    })
}

//删除全部图片
export function deleteAllProfilePhoto(cid){
    return request({
        url: '/profile/deleteAll?cid='+cid,
        method: 'delete'
    })
}

//上传头像图片
export function uploadProfilePhoto(formData){
    return request({
        url: '/profile/upload',
        method: 'post',
        data:formData
    })
}