<template>
<svg t="1683084759567" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5239" width="200" height="200"><path d="M416 620.8c76.8 0 147.2-6.4 206.933333-17.066667 2.133333-12.8 8.533333-23.466667 17.066667-34.133333l149.333333-189.866667v-2.133333c0-8.533333-12.8-14.933333-19.2-8.533333-49.066667 42.666667-189.866667 72.533333-354.133333 72.533333S113.066667 409.6 61.866667 366.933333c-6.4-6.4-19.2-2.133333-19.2 8.533334V512c0 59.733333 166.4 108.8 373.333333 108.8zM627.2 659.2c0-2.133333-2.133333-2.133333-2.133333-4.266667-59.733333 12.8-132.266667 19.2-209.066667 19.2-164.266667 0-302.933333-29.866667-354.133333-72.533333-6.4-6.4-19.2-2.133333-19.2 6.4v136.533333C42.666667 804.266667 209.066667 853.333333 416 853.333333c123.733333 0 232.533333-17.066667 300.8-44.8l8.533333-100.266666h-21.333333c-32 0-61.866667-19.2-76.8-49.066667z" p-id="5240"></path><path d="M42.666667 279.466667a373.333333 108.8 0 1 0 746.666666 0 373.333333 108.8 0 1 0-746.666666 0Z" p-id="5241"></path><path d="M957.866667 595.2h-89.6l19.2-192c2.133333-19.2-19.2-23.466667-29.866667-8.533333l-168.533333 213.333333c-12.8 14.933333-2.133333 36.266667 14.933333 36.266667h89.6l-14.933333 192c-2.133333 19.2 19.2 23.466667 29.866666 8.533333l166.4-213.333333c12.8-17.066667 2.133333-36.266667-17.066666-36.266667z" p-id="5242"></path></svg>

</template>
     
     <script>
     import { defineComponent } from 'vue'
     export default defineComponent({
       name: 'CacheIcon'
     })
     </script>
     
     <style scoped>
     .icon {
       display: inline-block;
       width: 2em;
       height: 2em;
       fill: currentColor;
       vertical-align: middle;
     }
     </style>
     