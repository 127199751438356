<template>
    <div>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>密码设置</span>
                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
            </div>
            <el-row style="display: flex;align-items: center;margin-bottom: 20px;">
                <span  class="input_label">旧密码 </span>
                <el-col :span="8">
                    <el-input style="display: inline;" type="password" v-model="oldpsw"  maxlength="16"></el-input>
                </el-col>
            </el-row>
            <el-row style="display: flex;align-items: center;margin-bottom: 20px;">
                <span class="input_label">新密码 </span>
                <el-col :span="8">
                    <el-input style="display: inline;" show-password="true" type="password" v-model="psw"  maxlength="16"></el-input>
                </el-col>
            </el-row>

            <el-row style="display: flex;align-items: center;margin-bottom: 15px;">
                <span class="input_label">确认密码 </span>
                <el-col :span="8">
                    <el-input style="display: inline;" show-password="true" type="password"  v-model="repsw"  maxlength="16"></el-input>
                </el-col>
            </el-row>
            <el-row style="text-align: center;">
                <el-col :span="10">
                    <el-button @click="ensureUpdatePassword" show-password="true" :disabled="oldpsw.trim() == '' || psw.trim() == '' || repsw.trim() == ''" style="margin: 0px auto;width: 120px;" size="medium" type="primary">提交</el-button>
                </el-col>
            </el-row> 
        </el-card>
        
    </div>
</template>
<script>
import {updateUserPassword} from '../api/user'
export default {
    name:'UpdatePassword',
    data(){
        return{
            oldpsw:'',
            psw:'',
            repsw:''
        }
    },
    methods:{
        ensureUpdatePassword(){
            //弹出确认框提醒用户
            this.$confirm('确认修改密码吗', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                callback: this.handleUpdatePassword
            })
        },
        handleUpdatePassword(action,instance){
            //如果用户选择了取消，则退出函数
            if(action === 'cancel' || action == 'close') return;
            //获取当前登录的用户信息
            let user = JSON.parse(localStorage.getItem("user"));
            //如果没有用户信息，则返回登录页
            if(typeof(user) == 'undefined' || user == null || user.id == null || user.id == ''){
                this.$message.warning("请重新登录！")
                this.logout()
                return;
            }
            //检查密码是否一致
            if(this.psw != this.repsw){
                this.$message.warning("密码不一致，请重新输入");
                return;
            }
            updateUserPassword(user.id,this.oldpsw,this.psw).then(res=>{
                if(res.data.status){
                    //密码修改成功
                    this.$message.success(res.data.msg+",3秒后自动跳转到登录页")
                    setTimeout(()=>{
                        this.logout()
                    },3000)
                }else{
                    //密码修改失败
                    this.$message.error(res.data.msg);
                }
               
            }).catch(error=>{
                this.$message.error("系统异常，请稍后再试")
            })

        }


    }
    
}
</script>
<style scoped>
.input_label{
    margin-right: 15px;
    width: 100px;
    justify-content: space-around;
}
</style>