import request from "../utils/request";


//获取壁纸分类
export function getWallpaperCategory(currentPage,pageSize) {
    return request({
        url: '/wallcategory/getListByPage?page='+currentPage+'&limit='+pageSize,
        method: 'get'
    })
}

//根据id获取壁纸分类
export function getWallpaperCategoryByCid(cid) {
    return request({
        url: '/wallcategory/findByCid?cid='+cid,
        method: 'get'
    })
}

//添加壁纸分类
export function addWallpaperCategory(wallcategory) {
    return request({
        url: '/wallcategory/add',
        data:wallcategory,
        method: 'post'
    })
}


//修改壁纸分类
export function updateWallpaperCategory(wallcategory) {
    return request({
        url: '/wallcategory/edit',
        data:wallcategory,
        method: 'put'
    })
}

//删除壁纸分类
export function deleteWallpaperCategory(cid) {
    return request({
        url: '/wallcategory/delete?cid='+cid,
        method: 'delete'
    })
}

//获取头像分类
export function getProfileCategory(currentPage,pageSize) {
    return request({
        url: '/profileCategory/getListByPage?page='+currentPage+'&limit='+pageSize,
        method: 'get'
    })
}


//删除头像分类
export function deleteProfileCategory(cid) {
    return request({
        url: '/profileCategory/delete?cid='+cid,
        method: 'delete'
    })
}

//添加头像分类
export function addProfileCategory(profileCategory) {
    return request({
        url: '/profileCategory/add',
        data:profileCategory,
        method: 'post'
    })
}

//根据id获取壁纸分类
export function getProfileCategoryByCid(cid) {
    return request({
        url: '/profileCategory/findByCid?cid='+cid,
        method: 'get'
    })
}

//修改头像分类
export function updateProfileCategory(profileCategory) {
    return request({
        url: '/profileCategory/edit',
        data:profileCategory,
        method: 'put'
    })
}

